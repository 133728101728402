import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public snackBar: MatSnackBar,
    private zone: NgZone,
    private _router: Router) { }

  showMessage(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'OK', {
        duration: 10000
      });
    });
  }

  showSuccessMessage(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'OK', {
        panelClass: 'validMessage',
        duration: 10000
      });
    });
  }

  showSuccessMessageNavigate(message: string, link: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'See Details', {
        panelClass: 'validMessage',
        duration: 10000
      })
        .onAction()
        .subscribe(() => this._router.navigateByUrl(link));
    });
  }

  showErrorMessage(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, '', {
        panelClass: 'errorMessage',
        duration: 10000
      });
    });
  }

  showWarningMessage(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'OK', {
      });
    });
  }
}