import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUser } from '../interfaces/user';
import { AuthService } from '../service/auth.service';
import { ColorService } from '../service/color.service';
import { FormDirtyService } from '../service/form-dirty.service';
import { SidebarService } from '../service/sidebar.service';
import { TitleService } from '../service/title.service';
import { Router } from '@angular/router';
import { EcklerRouteConfig } from '../eckler-route-config';
import { SecureMailLibService } from 'secure-mail-lib';
import { UserTypeEnum } from '../enum/user-type-enum';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  detailSubscription: Subscription;
  userContextMenuItems: any[] = [];
  activeComponent: any;
  isOpened: boolean;
  detailOpenned: boolean;
  currentUser: IUser;
  sideBarSubs: Subscription;
  title = '';
  titleSub: Subscription;
  @ViewChild('sidenav') sidenav: MatSidenav;
  authsub: Subscription;
  bd_mails: any = [];
  polling: any;
  id: string;
  auth_token: string = "";
  mailSubscriber: any = {};
  newMessageCount: number = 0;
  isSecureMailEnabled: boolean = false;

  constructor(private _sideBar: SidebarService,
    private _dirty:FormDirtyService,
    private _auth: AuthService,
      private _titleService: TitleService,
      private cdRef: ChangeDetectorRef,
      private _color: ColorService,
      private _router: Router,
      private mailService: SecureMailLibService) { }

  ngOnInit() {

    this.authsub = this._auth.currentUser.subscribe(u => {
      if(u) {
        this.currentUser = u;
        this._color.updatePrimaryColor(this.currentUser.primaryColorCode ?? '#2b6178');
        this._color.updateAccentColor(this.currentUser.secondaryColorCode ?? '#49BFB5');

        this.id = u.mailboxes[0].person.id;
        this.isSecureMailEnabled = u.isSecureMailEnabled;
        
        if (this.id && this.id != '0') {
          // Mail related calls
          this.bd_mails = u.mailboxes;
          this.auth_token = u.mailboxes[0].mailToken;
          this.mailService.getNewMessageCount(this.auth_token, this.id).subscribe();
          this.polling = setInterval(() => {
            this.mailService.getNewMessageCount(this.auth_token, this.id).subscribe();
          }, 1800000);
        }
      }
    });

    this.mailSubscriber = this.mailService.newCount$.subscribe(m => {
      console.log('New message count fired: ' + JSON.stringify(m));
      if (m) {
        this.newMessageCount = m.count;
        if (m.id !== this.id) {
          this.id = m.id;
          const bd_m = this.bd_mails.find((p: { person: { id: string; }; }) => p.person.id === m.id);
          if (bd_m !== null && bd_m !== undefined) {
            this.auth_token = bd_m.mailToken;
          }
        }

      } else {
        this.newMessageCount = 0;
      }
    });

    this.sideBarSubs = this._sideBar.sidebarSubject.subscribe(x => {
      if (x === true) {
        if(this.sidenav?.mode) {
          this.sidenav.toggle();
        }
      }
    });
    this.titleSub = this._titleService.title.subscribe(x => {
      this.title = x;
      this.cdRef.detectChanges();

    });
  }

  mail() {
    if(this.currentUser.userType === UserTypeEnum.Member) {
      this._router.navigateByUrl(EcklerRouteConfig.planMembersMail());
    } else {
      this._router.navigateByUrl(EcklerRouteConfig.planPlanMembersMail());
    }
  }

  ngOnDestroy(): void {
    this.authsub.unsubscribe();
    this.titleSub.unsubscribe();
    if (this.sideBarSubs) {
      this.sideBarSubs.unsubscribe();
    }
    this.removeMailSubscription();
  }

  removeMailSubscription() {
    if (this.mailSubscriber && typeof this.mailSubscriber.unsubscribe === 'function') {
      this.mailSubscriber.unsubscribe();
    }

    if (this.polling) {
      clearInterval(this.polling);
    }
  }

  signOut(){
    this._dirty.formIsClean();
    this._auth.signOut();
  }

}
