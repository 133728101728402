import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageEnum } from '../enum/language';
import { IPlanWelcomeInfoModel } from '../interfaces/iplan-welcome-info-model';
import { IPlan } from '../interfaces/plan';
import { IBaseResponse } from '../interfaces/system/baseresponse';
import { PagingRequest } from '../interfaces/system/paging-request';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),

    params: new HttpParams()
  };
  constructor(private _http: HttpClient) {
  }

  getPlans(query: PagingRequest) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'plan', {
      params: new HttpParams()
        .set('filter', query.criterias)
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
    });
  }

  getTheSlug(slug: string) {

    if (!slug || slug.length === 0) {

      // get the url
      const url = window.location.href;

      // remove the http:// or https://
      const urlWithoutProtocol = url.replace(/^https?:\/\//i, '');

      // split it into parts base on the .
      const domainParts = urlWithoutProtocol.split('.');

      var minNumOfParts = 2;
      if (domainParts.some(part => part.includes('azurewebsites'))) {
        minNumOfParts = domainParts.length; // this will force the use of the default slug
      } else if (domainParts.some(part => part.includes('localhost'))) {
        minNumOfParts = 1; // Update minNumOfParts if needed
       }

      // get the subDomain
      slug = domainParts.length > minNumOfParts ? domainParts[0] : environment?.defaultSlug ?? '';

      // remove www
      if (slug === 'www') slug = '';
    }

    // If we have a valid slug, return it, otherwise set it to ''
    return this.getSlugValidity(slug) ? slug : '';
  }

  getSlugValidity(slug: string){
    return this._http.get<boolean>(environment.mainApiUrl+'plan/slug/'+slug);
  }

  createPlan(plan:IPlan) {
    return this._http.post<IPlan>(environment.mainApiUrl+ 'plan', plan);
  }

  getPlan(planId: number) {
    return this._http.get<IPlan>(environment.mainApiUrl+ 'plan/' + planId);
  }

  getMyPlan() {
    return this._http.get<IPlan>(environment.mainApiUrl+ 'plan/my');
  }

  updatePlan(planId: number, plan:IPlan) {
    return this._http.put(environment.mainApiUrl+ 'plan/'+ planId, plan);

  }

  deletePlan(planId: number) {
    return this._http.delete(environment.mainApiUrl+ 'plan/' + planId);
  }

  uploadLogo(file: File){
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this._http.post<any>(environment.mainApiUrl+ 'plan/logo', formData,
      {
        reportProgress: true,
        responseType: 'json'
      }
    );
  }

  getPlanLogo(slug: string): Observable<Blob> {
    return this._http.get(environment.mainApiUrl + 'plan/logo', {
      params: new HttpParams()
        .set('slug', slug),
      responseType: 'blob' });
  }

  getCurentLogo():Observable<Blob>{
    return this._http.get(environment.mainApiUrl + 'plan/currentlogo', {
      params: new HttpParams(),
      responseType:'blob'
    }
    );
  }

  getPlanInfo(slug: string, language: LanguageEnum){
    return this._http.get<IPlanWelcomeInfoModel>(environment.mainApiUrl + 'plan/logindetails', {
      params: new HttpParams()
        .set('slug', slug)
        .set('language', language)
    });
  }

  getCurentPlanLogo(id: Number, type: string):Observable<Blob>{
    return this._http.get(environment.mainApiUrl + 'plan/currentlogo/' + id + '/' + type, {
      params: new HttpParams(),
      responseType:'blob'
    }
    );
  }
}