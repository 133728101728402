import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserTypeEnum } from '../enum/user-type-enum';
import { ISignalRConnectionInfo } from '../interfaces/signalr-connection-info';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;

  userType = UserTypeEnum.Member;
  constructor(private _http: HttpClient,
    private _notification: NotificationService,
    private _auth: AuthService) {
    _auth.currentUser.subscribe(u => {
      if (u) {
        this.userType = u.userType
      }
    });
  }

  public startConnection = () => {

    this.getConnectionInfo().subscribe((info) => {
      const options = {
        accessTokenFactory: () => info.accessToken
      };

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(info.url, options)
        .build();

      this.hubConnection
        .start();
    });
  };

  public addDataListener = (srhandle: string) => {
    this.hubConnection.on(srhandle, (message) => {
      const urlSegment4UserType = this.userType === UserTypeEnum.SuperUser ? '/eckler' : '/plan';
      const notificationType = message.split('-')[0];
      const identifier = message.split('-')[1];
      if (notificationType === 'document') {
        this._notification.showSuccessMessageNavigate('File processing is done.', urlSegment4UserType + '/review/' + identifier);
      } else if (notificationType === 'import') {
        this._notification.showSuccessMessageNavigate('Member import is done.', urlSegment4UserType + '/import/' + identifier);
      }
    });
  };

  private getConnectionInfo(): Observable<ISignalRConnectionInfo> {
    const requestUrl = environment.signalRUrl;
    return this._http.get<ISignalRConnectionInfo>(requestUrl);
  }
}