<div class="container py-5 mb-2 mt-5">
    <div class="row text-center">
        <div class="col">
            <img src="/assets/images/eckler-logo.svg" *ngIf="!loading && !isImageValid" alt="logo" class="p-0 m-0">
            <img [src]="logoToDisplay" alt="logo" *ngIf="!loading && isImageValid" class="p-0 m-0" /> 

        </div>
    </div>
    <div class="row justify-content-md-center mt-3">
        <div class="col-12 col-sm-6">
            <mat-card class="p-5">
                <mat-spinner *ngIf="submitted || loading"></mat-spinner>
                <br />
                <h4>{{ 'Common.MFAAuthentication' | translate }}</h4>
                <form *ngIf="form" (ngSubmit)="submit()" [formGroup]="form">
                    <mat-form-field class="w-100">
                        <input matInput required placeholder="{{'Login.EnterCode' | translate}}" formControlName="code">
                    </mat-form-field>
                    <a class="fakelink mb-2" (click)="resendCode()">{{'Login.ResendCode' | translate}}</a>


                    <div class="form-row">
                        <button mat-flat-button color="accent" [disabled]="!form.valid || submitted">{{ 'Actions.Continue' | translate }}</button>                        
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>