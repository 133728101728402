
      <div class="row justify-content-between align-items-center mx-2 mt-3 mb-2">
        <div class="col justify-content-end d-flex" >
            <button mat-flat-button color="accent" (click)="createDialog()" matTooltip="{{'Tooltip.Administrator' | translate}}" matTooltipClass ="tooltip"><mat-icon>person_add_alt</mat-icon>{{'Actions.Administrator' | translate}}</button>
      </div>
      </div>

      <mat-spinner *ngIf="loading"></mat-spinner>
      <mat-table *ngIf="dataSource && dataSource.data.length > 0" [dataSource]="dataSource"class="mat-elevation-z8 mx-2">
        <ng-container matColumnDef="fullName">
          <mat-header-cell  *matHeaderCellDef class="col"> {{'Common.FullName' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let administrator" class="col"> {{administrator.firstName}}&nbsp;{{administrator.lastName}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef class="col-2"> {{'Common.Email' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let administrator" class="col-2"> {{administrator.email}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef class="col-2"> {{'Common.Phone' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let administrator" class="col-2"> {{administrator.mobile | mask: '(000) 000-0000'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
          <mat-header-cell *matHeaderCellDef class="col-1"> {{'Common.Language' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let administrator" class="col-1"> {{'Enum.Language' | translate:{type:administrator.language} }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mainContact">
          <mat-header-cell  *matHeaderCellDef class class="col-1 justify-content-center text-center" > {{'Administrator.isMainContact' | translate}} </mat-header-cell>
          <mat-cell  *matCellDef="let administrator" class="col-1 justify-content-center">
            <button mat-icon-button color="accent" *ngIf="administrator.isMainContact" disabled>
              <mat-icon>how_to_reg</mat-icon>
            </button>
            <button mat-icon-button color="accent" *ngIf="!administrator.isMainContact" (click)="makeMainContact(administrator.id);  $event.stopPropagation()">
              <mat-icon>person_add_alt</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="invitationSent">
          <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center text-center"> {{'Common.InvitationSent' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let administrator" class="col-1 justify-content-center"> <mat-icon *ngIf="administrator.invitationSentViaEmail === true">mark_email_read</mat-icon> </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sendInvite">
          <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center text-center"> {{'Actions.SendInvite' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let administrator" class="col-1 justify-content-center"> <button mat-icon-button color="accent" (click)="sendInvite(administrator.id);  $event.stopPropagation()"><mat-icon>forward_to_inbox</mat-icon></button> </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center"> {{'Actions.Delete' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let administrator" class="col-1 justify-content-center"> <button mat-icon-button color="warn" (click)="deleteAdmin(administrator.id);  $event.stopPropagation()"><mat-icon>delete</mat-icon></button></mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="getAdministratorDetails(row)" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <app-paging-helper></app-paging-helper>
        <app-no-data *ngIf="(!dataSource || dataSource.data.length <= 0)  && !loading"></app-no-data>
