import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeleteItemComponent } from 'src/app/dialog/delete-item/delete-item.component';
import { UploadGeneralDocumentComponent } from 'src/app/dialog/upload-general-document/upload-general-document.component';
import { UploadPersonalDocumentComponent } from 'src/app/dialog/upload-personal-document/upload-personal-document.component';
import { DeletionTypeEnum } from '../../enum/deletion-type';
import { DocumentEnum } from '../../enum/document-enum';
import { UserTypeEnum } from '../../enum/user-type-enum';
import { IDocuments } from '../../interfaces/documents';
import { PagingRequest } from '../../interfaces/system/paging-request';
import { AuthService } from '../../service/auth.service';
import { DocumentService } from '../../service/document.service';
import { NotificationService } from '../../service/notification.service';
import { PagingHelperService } from '../../service/paging-helper.service';
import { PlanMemberService } from '../../service/planmember.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  providers: [{ provide: PagingHelperService }]

})
export class DocumentsComponent implements OnInit, OnDestroy {
  @Input() memberId: number;
  @Input() type: DocumentEnum;
  detailOpenned = false;
  selectedMemberId: IDocuments;
  listOfDocument: IDocuments[] = [];
  pagingHelpSubs: Subscription;
  authsub: Subscription;
  criterias = '';
  active: string;
  direction: string;
  loading = false;
  usertype: UserTypeEnum;
  usertypeEnum = UserTypeEnum;

  displayedColumns: string[] = ['name', 'date', 'download', 'delete', 'unread'];
  dataSource = new MatTableDataSource(this.listOfDocument);

  constructor(
    public dialog: MatDialog,
    private _document: DocumentService,
    public _pagingHelper: PagingHelperService,
    private _notification: NotificationService,
    private _auth: AuthService,
    private _translate: TranslateService, 
    private _memberService: PlanMemberService) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.authsub = this._auth.currentUser.subscribe(x => this.usertype = x.userType);
    if (this.usertype == this.usertypeEnum.Member) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'delete');
    }
    
    if(this.usertype === this.usertypeEnum.Member) {
      this._memberService.getMyPlanMember().toPromise().then(res => {
        this.memberId = res.id;//Number(res.memberId);         
        this.getData(this.criterias);
      });
    } else {
      this.getData(this.criterias);
    }
    this.pagingHelpSubs = this._pagingHelper.loadMore.subscribe(load => {
      if (load === true) {
        this.getData(this.criterias, this.active, this.direction);

      }
    });
  }

  getData(criteria: string, active?: string, direction?: string) {
    this.loading = true;
    const query: PagingRequest = {
      criterias: criteria,
      paging: this._pagingHelper.buildPaging(),
      sortBy: active,
      sortDirection: direction,
      type: this.type,
      memberId: this.memberId
    };
    this._document.getDocument(query)
      .subscribe({
        next: res => {
          this.listOfDocument = [...this.listOfDocument, ...res.payload];
          this.dataSource.data = [];
          this.dataSource.data.push(...res.payload);
          this._pagingHelper.updatePaging(res.metadata.total, this.listOfDocument.length);
          this.dataSource.sort = this.sort;
          this.loading = false;
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
  }

  sortData(e: Sort) {
    this.active = e.active;
    this.direction = e.direction;
    this.dataSource.data = [];
    this._pagingHelper.resetPaging();
    this.getData(this.criterias, e.active, e.direction);
  }

  newCriteria(value: string) {
    this.dataSource.data = [];
    this.criterias = value;

    this._pagingHelper.resetPaging();
    this.getData(value, this.active, this.direction);

  }

  downloadDocument(id: number) {
    this._document.download(id)
      .subscribe({
        next: x => {
          const file = new Blob([x], { type: 'application/pdf' });
          const fileURL = window.URL.createObjectURL(file);
          window.open(fileURL);
          window.location.reload();
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
  }

  uploadDocument(type: DocumentEnum) {
    if (type === DocumentEnum.general) {
      const dialogRef = this.dialog.open(UploadGeneralDocumentComponent, {
        width: '350px'
      });
      dialogRef.afterClosed().subscribe(result => {
        this.listOfDocument = [];
        this._pagingHelper.resetPaging();
        this.getData(this.criterias, this.active, this.direction);
      });
    }
    if (type === DocumentEnum.personal) {
      const dialogRef = this.dialog.open(UploadPersonalDocumentComponent, {
        width: '350px',
        data: this.memberId
      });
      dialogRef.afterClosed().subscribe(result => {
        this.listOfDocument = [];
        this._pagingHelper.resetPaging();
        this.getData(this.criterias, this.active, this.direction);
      });
    }
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DeleteItemComponent, {
      width: '350px',
      data: { deletionType: DeletionTypeEnum.document }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._document.deleteDocument(id).subscribe(() => {
          this._notification.showSuccessMessage(this._translate.instant('Message.DeleteSuccess'));
          let data = this.dataSource.data;
          data = [];
          this.listOfDocument = [];
          this.dataSource.data = data;
          this._pagingHelper.resetPaging();
          this.getData(this.criterias, this.active, this.direction);
        }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.pagingHelpSubs.unsubscribe();
    this.authsub.unsubscribe();
  }
}
