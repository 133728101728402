<div class="row mt-2" *ngIf="import">
    <div class="col-7"  *ngIf="import.failCount > 0 ">
<mat-card class="mx-2">
    <mat-card-title class="mb-3">{{'Common.FailedImportation'| translate}}</mat-card-title>
    <mat-card-content>
        <mat-spinner *ngIf="loading"></mat-spinner>
        <mat-table *ngIf="dataSource && dataSource.data.length > 0" [dataSource]="dataSource"  class="mat-elevation-z8 mx-2">
          <ng-container matColumnDef="rowIndex">
            <mat-header-cell  *matHeaderCellDef class="col-2"> {{'Common.RowIndex' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let import" class="col-2"> {{import.rowIndex}}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef class="col"> {{'Common.Notes' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let import" class="col"> {{import.notes}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        
    </mat-card-content>
</mat-card>
</div>
<div class="col">

<mat-card class="mx-2">
 <mat-card-title class="mb-3">{{'Importation.Status'|translate}}</mat-card-title>
 <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div *ngIf="import">
    <div class="row justify-content-between"><div class="col"><strong>{{'Importation.TotalProceded' |translate}}</strong></div><div class="col"><strong>{{import.totalImportsProcessed}}</strong></div></div>
    <mat-divider class="py-3"></mat-divider>
    <div class="row justify-content-between"><div class="col">{{'Importation.Successcount' |translate}}</div><div class="col"> {{import.successCount}}</div></div>
     <div *ngIf="import.failCount > 0" class="row justify-content-between"><div class="col text-danger" >{{'Importation.FailedCount' |translate}}</div><div class="col text-danger"> {{import.failCount}}</div></div>

    <div class="row justify-content-between"><div class="col">{{'Importation.StartDate' |translate}}</div><div class="col">{{import.processStartedOn| date: 'MM/dd/y, h:mm:ss a'}}</div></div>
     <div class="row justify-content-between"><div class="col">{{'Importation.EndDate' |translate}}</div><div class="col">{{import.processEndedOn| date: 'MM/dd/y, h:mm:ss a'}}</div></div>
     
     </div>
 </mat-card-content>

</mat-card>
</div>
</div>