import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/module/material.module';
import { LoginComponent } from './login/login/login.component';
import { SetupAccountComponent } from './login/setup-account/setup-account.component';
import { JoinComponent } from './login/join/join.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { AuthInterceptor } from './shared/service/auth-interceptor.service';
import { UploadGeneralDocumentComponent } from './dialog/upload-general-document/upload-general-document.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { SwitchAccountComponent } from './dialog/switch-account/switch-account.component';
import { MFAComponent } from './login/mfa/mfa.component';
import { ApplicationInitializerFactory } from './shared/service/appinitializerfactory';
import { UploadPersonalDocumentComponent } from './dialog/upload-personal-document/upload-personal-document.component';
import { ContactSlugComponent } from './shared/component/contactslug/contactSlug.component';
import { DatePipe } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { MatBadgeModule} from '@angular/material/badge'; 
import { MatIconModule} from '@angular/material/icon'; 
import { MatButtonModule} from '@angular/material/button';
import { SecureMailLibModule, SecureMailLibService, SECURE_MAIL_BASE_URL } from 'secure-mail-lib';
import { environment } from 'src/environments/environment';
import { MailComponent } from './shared/component/mail/mail.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');

}

export const options: null | Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        JoinComponent,
        SetupAccountComponent,
        ResetPasswordComponent,
        UploadGeneralDocumentComponent,
        ChangePasswordComponent,
        SwitchAccountComponent,
        MFAComponent,
        UploadPersonalDocumentComponent,
        ContactSlugComponent,
        ForgotPasswordComponent,
        MailComponent
    ],
    imports: [
        BrowserModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        MaterialModule,
        MatDialogModule,
        MatBadgeModule,
        MatIconModule,
        MatButtonModule,
        SecureMailLibModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule
    ],
    providers: [
        DatePipe,
        {
            provide: APP_INITIALIZER,
            useFactory: ApplicationInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        }, {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: SECURE_MAIL_BASE_URL, useValue: environment.secureMailUrl },
        SecureMailLibService
    ],
    bootstrap: [AppComponent],
    exports: [
        TranslateModule,
        NgxMaskModule,
        SecureMailLibModule
    ]
})
export class AppModule { }
