<mat-toolbar class="titleBar mat-elevation-z3" color="primary">
  
  <ng-container>
    <div class="d-flex align-items-center">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span>
    {{title}}
    </span>
  </div>
  </ng-container>
<div>
  <button mat-button [matMenuTriggerFor]="userContextMenu" class="userName">{{currentUser.firstName}}</button>
    <button mat-button *ngIf="isSecureMailEnabled" class="mail" (click)="mail()" (keyup.enter)="mail()">
      <mat-icon [matBadge]="newMessageCount" [matBadgeHidden]="newMessageCount == 0" matBadgeColor="warn" matBadgeSize="medium">email</mat-icon>
    </button>|
  <button mat-button class="userName" (click)="signOut()">{{'Topbar.Signout' | translate}}</button>
</div>
</mat-toolbar>
<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav mode="over" #sidenav [(opened)]="isOpened" class="sidebar">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet class="router-outlet">
      </router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <mat-menu #userContextMenu="matMenu" xPosition="before">
    <ng-template matMenuContent>
      <app-menu-context></app-menu-context>
    </ng-template>
  </mat-menu>

  <app-footer></app-footer>
