<h2 class="dialogTitle d-flex justify-content-between">{{member.firstName}}&nbsp;{{member.lastName}} 
    <mat-icon *ngIf="member.registered" color="accent" matTooltip="{{'Member.Registred'|translate}}" matTooltipClass ="tooltip">verified</mat-icon>
    <mat-icon *ngIf="!member.registered && member.invitationSentViaEmail" color="accent" matTooltip="{{'Member.InvitationSent'|translate}}" matTooltipClass ="tooltip">mark_email_read</mat-icon>

</h2>
    

<mat-dialog-content *ngIf="membermodificationForm">
<form [formGroup]="membermodificationForm">
    <mat-slide-toggle class="withoutformfield" (change)="makeFormDirty()" formControlName="enabled">{{'Member.Enabled' |translate}}</mat-slide-toggle>
    <mat-form-field class="w-100">
        <mat-label>{{'Common.FirstName' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.FirstName' | translate}}" formControlName="firstName">
        <mat-error *ngIf="membermodificationForm.controls.firstName.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Common.LastName' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.LastName' | translate}}" formControlName="lastName">
        <mat-error *ngIf="membermodificationForm.controls.lastName.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100 mb-3">
        <mat-label>{{'Common.MemberId' | translate}}</mat-label>
        <input  matInput placeholder="{{'Common.MemberId' | translate}}" formControlName="memberId">
        <mat-hint>{{'Warning.MemberID' | translate}}</mat-hint>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>{{'Common.Email' | translate}}</mat-label>
        <input matInput placeholder="{{'Common.Email' | translate}}" formControlName="email">

        <mat-error *ngIf="membermodificationForm.controls.email.hasError('email')">
            {{'Error.EmailInvalid' | translate}}
        </mat-error>
    </mat-form-field>


    <mat-form-field class="w-100">
        <mat-label>{{'Common.Phone' | translate}}</mat-label>
        <input mask="(000) 000-0000"  matInput placeholder="{{'Common.Phone' | translate}}"
            formControlName="mobile">
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>{{'Common.Language' | translate}}</mat-label>
          <mat-select required formControlName="language">
           <mat-option *ngFor="let key of langKeys" [value]="key">{{'Enum.Language'| translate:{type:key} }}</mat-option>
          </mat-select>
          <mat-error *ngIf="membermodificationForm.controls.language.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-slide-toggle class="withoutformfield" (change)="makeFormDirty()" formControlName="optIn">{{'Member.OptIn' |
        translate}}</mat-slide-toggle>
       


   </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-flat-button (click)="cancel()" class="cancel-button">
        {{'Actions.Cancel' | translate}}
    </button>
    <button type="button" mat-flat-button (click)="save()"  color="accent" [disabled]="!membermodificationForm.valid">
        {{'Actions.Save' | translate}}
    </button>

</mat-dialog-actions>