import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldTypeEnum } from '../../enum/field-type';
import { IVerificationField } from '../../interfaces/verification-field';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { default as _rollupMoment } from 'moment';
import * as _moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY/MM/DD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]}, 
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class QuestionComponent implements OnInit {
  public datepipe: DatePipe = new DatePipe('en-US')
  maxDate:Date;
  @Input() question!: IVerificationField;
  @Input() form!: UntypedFormGroup;
  FieldTypeEnum = FieldTypeEnum;

  constructor() { }
  ngOnInit(): void {
    this.maxDate = new Date();
  }

  get isValid() {
    return this.form.controls[this.question.id.toString()].valid;
  }

}
