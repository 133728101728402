import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { PlanMemberService } from 'src/app/shared/service/planmember.service';
import { SignalRService } from 'src/app/shared/service/signalr.service';

@Component({
  selector: 'app-import-members',
  templateUrl: './import-members.component.html'
})
export class ImportMembersComponent {

  constructor(public dialogRef: MatDialogRef<ImportMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public planId: number,
    private _memberService: PlanMemberService,
    private _notification: NotificationService,
    private _translate: TranslateService,
    private _planmember: PlanMemberService,
    private _sr: SignalRService) { }
  @ViewChild('fileInput') fileInput: any;
  uploading = false;

  uploadExcelFile() {
    this.uploading = true;
    const fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      const fileToUpload = fi.files[0];
      this._memberService
        .importMembersFromExcelFile(fileToUpload, this.planId)
        .subscribe(result => {
          this._sr.addDataListener(result.fileNameOnAzure);
          this._notification.showSuccessMessage(this._translate.instant('Message.ImportSuccess'));
          this.uploading = false;
          this.dialogRef.close();
        }).add(() => this.uploading = false);
    }
  }

  getTemplate() {
    this._planmember.getImportTemplate(this.planId)
      .subscribe({
        next: x => {
          const newBlob = new Blob([x], { type: 'application/vnd.ms-excel' });
          const url = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'Template.xlsx';
          link.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          }));

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
            link.remove();
          }, 100);
        },
        error: error => {
          this._notification.showErrorMessage(error);
        }
      });
  }
}
