import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html'
})
export class ReviewComponent implements OnInit {
  uploadId: number;

  constructor(private _activatedRoute:ActivatedRoute) { }

  ngOnInit(){

    this._activatedRoute.paramMap.subscribe(paramMap => this.onChangeUrlParameters(paramMap));
  }

  onChangeUrlParameters(paramMap: ParamMap) {

    this.uploadId = +paramMap.get('id');

  }
}
