import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeletionTypeEnum } from 'src/app/shared/enum/deletion-type';

@Component({
  selector: 'app-delete-item',
  templateUrl: './delete-item.component.html',
  styleUrls: ['./delete-item.component.scss']
})
export class DeleteItemComponent implements OnInit {
  deletionType: DeletionTypeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { deletionType: DeletionTypeEnum }

  ) { }

  ngOnInit(): void {
    this.deletionType = this.data.deletionType;
  }

}
