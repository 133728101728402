import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBatchInvite } from '../interfaces/batch-invite';
import { IBatchInviteResult } from '../interfaces/batch-invite-result';
import { IImport } from '../interfaces/iimport';
import { IImportFailed } from '../interfaces/iimport-failed';
import { IPlanMember } from '../interfaces/planmember';
import { IBaseResponse } from '../interfaces/system/baseresponse';
import { PagingRequest } from '../interfaces/system/paging-request';

@Injectable({
  providedIn: 'root'
})
export class PlanMemberService {

  constructor(private _http: HttpClient) {
  }

  getPlanMembers(query: PagingRequest) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'member', {
      params: new HttpParams()
        .set('filter', query.criterias)
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
        .set('planId', query.planId)
    });
  }

  exportToExcel(query: PagingRequest, planId?: number): Observable<Blob> {
    return this._http.get(`${environment.mainApiUrl}member/export`, {
      params: new HttpParams()
        .set('planId', planId)
        .set('filter', query.criterias)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection),
      responseType: 'blob'
    });
  }
  getImportTemplate(planId: number): Observable<Blob> {
    return this._http.get(`${environment.mainApiUrl}member/template`, {
      params: new HttpParams()
        .set('planId', planId),
      responseType: 'blob'
    });
  }

  printInvitations(invitations: IBatchInvite): Observable<Blob> {
    return this._http.post(`${environment.mainApiUrl}member/printinvitations`, invitations, {
      responseType: 'blob'
    });
  }

  importMembersFromExcelFile(fileToUpload: any, planId?: number) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this._http.post<any>(environment.mainApiUrl + 'member/import', formData, {
      params: new HttpParams()
        .set('planId', planId)
    });
  }

  createPlanMembers(member: IPlanMember) {
    return this._http.post<IPlanMember>(environment.mainApiUrl + 'member', member);
  }

  updatePlanMember(memberId: number, member: IPlanMember) {
    return this._http.put(environment.mainApiUrl + 'member/' + memberId, member);
  }

  inviteMember(memberId: number) {
    return this._http.put(environment.mainApiUrl + 'member/invite/' + memberId, null);
  }

  getPlanMember(memberId: number) {
    return this._http.get<IPlanMember>(environment.mainApiUrl + 'member/' + memberId);
  }

  getMyPlanMember() {
    return this._http.get<IPlanMember>(environment.mainApiUrl + 'member/my');
  }

  deletePlanMember(memberId: number) {
    return this._http.delete(environment.mainApiUrl + 'member/' + memberId);
  }

  sendBatchInvites(invitations: IBatchInvite) {
    return this._http.post<IBatchInviteResult>(environment.mainApiUrl + 'member/invite', invitations);
  }

  getMyPlanWelcomeMessage() {
    return this._http.get<any>(environment.mainApiUrl + 'plan/my/message');
  }

  getLogins(query: PagingRequest) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'member/logins', {
      params: new HttpParams()
        .set('filter', query.criterias)
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
    });
  }

  getImport(id:number){
    return this._http.get<IImport>(environment.mainApiUrl + 'member/import/'+ id);
  }

  getImportfailed(query:PagingRequest, importId:number){
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'member/importfails/'+ importId, {
      params: new HttpParams()
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
    });
  }
}