import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Subscription } from 'rxjs';
import { SecureMailLibService } from 'secure-mail-lib';
import { Person, SecureEmailAddress } from '../../interfaces/user';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../service/user.service';
import { UserTypeEnum } from '../../enum/user-type-enum';
import { TitleService } from '../../service/title.service';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MailComponent implements OnInit {
  sender: Person;
  auth_token: string = "";
  to_list: any = [];
  category: any = [];
  mailboxes: any = [];
  mailbox: number;
  id: string;
  expiry: number = 0;
  show_completed: boolean = false; 
  hide_email: boolean = false;
  public section: string;
  public employerJSON: string;
  adminName: string;
  theme: string = 'secure-mail-theme';
  memberJSON: string;
  authsub: Subscription;
  bd_mails: any = [];
  polling: any;
  user: string;
  language: string;

  constructor(private _auth: AuthService,
    private mailService: SecureMailLibService, 
    private _translate:TranslateService,
    private userService: UserService,
    private _title:TitleService) { }

  ngOnInit(): void {
    this._title.updateTitle(this._translate.instant('Title.SecureMail'));
    this.language = this._translate.currentLang;
    this.authsub = this._auth.currentUser.subscribe(a => {
      if(a) { 
        this.id = a.mailboxes[0].person.id;
        console.log('Id: ' + this.id);

        if (this.id && this.id != '0') {      
          this.mailboxes = a.mailboxes;

          // secure mailbox id we are using Member.Id
          let index = this.mailboxes.findIndex((p: { person: { id: string; }; }) => p.person.id === this.id);
    
          if (index === -1) {
            index = 0;
          }
          console.log('Index: ' + index);
    
          this.mailbox = index;
          this.sender = a.mailboxes[index].person;
          this.auth_token = a.mailboxes[index].mailToken;
      }
      this.user = (a.userType === UserTypeEnum.Member) ? "Member" : "Administrator";
      this.expiry = (a.userType === UserTypeEnum.Member) ? 60 : 0;
      this.category = [a.planName]; //Radio button send email TO

      this.hide_email = (a.userType === UserTypeEnum.Member) ? true : false;
      //Gets all the to addresses to send emails
       this.userService.getToList(this.user.toString()).subscribe(data => {
        this.to_list = data;
      });
      
      this.mailboxes.forEach((m: { person: { id: any; email: any; name: any; category: any }; }) => {
        let member_index = this.to_list.findIndex((p: { id: any; }) => p.id === m.person.id);
        if (member_index === -1) {
          this.to_list.push({ category: m.person.category, id: m.person.id, email: m.person.email, name: m.person.name });
        }
      });
    }
  });
  }

  mailboxChanged(index: string | number): void {
    this.sender = this.mailboxes[index].person;
    this.auth_token = this.mailboxes[index].mailToken;
  }
}
