import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MenuItem } from '../class/menu-item';
import { EcklerRouteConfig } from '../eckler-route-config';
import { UserTypeEnum } from '../enum/user-type-enum';
import { AuthService } from '../service/auth.service';
import { PlanService } from '../service/plan.service';
import { SidebarService } from '../service/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Output() closeEvent = new EventEmitter<boolean>();
  menuItems: MenuItem[] = [];
  translations: string[] = [];
  logoToDisplay: any;
  isImageValid = false;
  isImageLoading = false;
  logourl: string;
  isSecureMailEnabled: boolean = false;
  sidebarSubs: Subscription;

  usertype: number;

  constructor(
    private _auth: AuthService,
    private _translate: TranslateService,
    private _sidebar: SidebarService,
    private _planService: PlanService

  ) { }

  ngOnInit() {
    this.getCurrentLogoFile();
    this._auth.currentUser.subscribe({
      next: res => {
        this.usertype = res.userType;
        this.logourl = res.logoUrl;
        this.isSecureMailEnabled = res.isSecureMailEnabled;
      }
    });

    this._translate
      .stream([
        'Sidebar.PlanMemberList',
        'Sidebar.PlanCompanyConfig',
        'Sidebar.EcklerPlanList',
        'Sidebar.EcklerCompanyConfig',
        'Sidebar.Documentation',
        'Sidebar.MemberConfiguration',
        'Sidebar.PersonalDocument',
        'Sidebar.GeneralDocument',
        'Sidebar.MemberPortal',
        'Sidebar.MemberLogins',
        'Sidebar.SecureMail'
      ])
      .subscribe((res: string[]) => {
        this.translations = res;
        this.buildMenu();
      });

    this.sidebarSubs = this._sidebar.sidebarUpdate.subscribe(
      x => {
        if (x === true) {
          this.updatelogoShown();
        }
      }
    );

  }

  closeSidebar() {
    this._sidebar.closeSidebar();
  }

  private buildMenu() {
    this.menuItems = [];

    if (this.usertype === +UserTypeEnum.Member) {

      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.MemberPortal'],
          EcklerRouteConfig.planMemberPortalRoute()

        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.PersonalDocument'],
          EcklerRouteConfig.memberPerosnaleDocument()

        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.GeneralDocument'],
          EcklerRouteConfig.memberGeneralDocument()

        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.MemberConfiguration'],
          EcklerRouteConfig.planmembersConfiguration()
        )
      );
      if(this.isSecureMailEnabled) {
        this.menuItems.push(
          new MenuItem(
            this.translations['Sidebar.SecureMail'],
            EcklerRouteConfig.planMembersMail()
          )
        );
      }
    }
    if (this.usertype === +UserTypeEnum.Administrator) {

      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.PlanMemberList'],
          EcklerRouteConfig.planPlanMembersListRoute()
        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.GeneralDocument'],
          EcklerRouteConfig.planGeneralDcoument()
        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.PlanCompanyConfig'],
          EcklerRouteConfig.planCieConfigRoute()
        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.MemberLogins'],
          EcklerRouteConfig.planAdminMembersLoginRoute()
        )
      );
      if(this.isSecureMailEnabled) {      
        this.menuItems.push(
          new MenuItem(
            this.translations['Sidebar.SecureMail'],
            EcklerRouteConfig.planPlanMembersMail()

          )
        );
      }
    }

    if (this.usertype === +UserTypeEnum.SuperUser) {

      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.EcklerPlanList'],
          EcklerRouteConfig.ecklerPlanListRoute()
        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.EcklerCompanyConfig'],
          EcklerRouteConfig.ecklerCieConfigRoute()
        )
      );
      this.menuItems.push(
        new MenuItem(
          this.translations['Sidebar.MemberLogins'],
          EcklerRouteConfig.membersLoginRoute()
        )
      );
    }

  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {

      this.logoToDisplay = reader.result;
      this.isImageValid = true;

    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getCurrentLogoFile(): void {
    this.isImageLoading = true;

    this._planService.getCurentLogo().subscribe({
      next: data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;

      },
      error: error => {
        this.isImageLoading = false;
        this.isImageValid = false;
      }
    });
  }

  updatelogoShown() {
    this.getCurrentLogoFile();
  }

  ngOnDestroy(): void {
    this.sidebarSubs.unsubscribe();
  }
}
