import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyComponent } from '../component/privacy/privacy.component';
import { TermUseComponent } from '../component/term-use/term-use.component';
import { ContactComponent } from '../component/contact/contact.component';
import { Subscription } from 'rxjs';
import { UserTypeEnum } from '../enum/user-type-enum';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy{
  authsub:Subscription;
  userType: UserTypeEnum;
  usertypeEnum = UserTypeEnum;

  constructor(public dialog:MatDialog, private _auth:AuthService) { }

  ngOnInit(): void {
    this.authsub = this._auth.currentUser.subscribe(x => this.userType = x.userType);
  }

  openTermOfUse(){
    this.dialog.open(TermUseComponent);
  }

  openPrivacy(){
    this.dialog.open(PrivacyComponent);
  }

  openContact(){
    this.dialog.open(ContactComponent,
      {width : '700px'});
  }

  ngOnDestroy(): void {
    this.authsub.unsubscribe();
  }

}
