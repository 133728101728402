import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IVerificationField } from '../interfaces/verification-field';

@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: IVerificationField[]) {
    const group: any = {};

    questions.forEach(question => {
      group[question.id.toString()] = new UntypedFormControl(null, Validators.required);
    });
    return new UntypedFormGroup(group);
  }
}