import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TermUseComponent } from 'src/app/shared/component/term-use/term-use.component';
import { EcklerRouteConfig } from 'src/app/shared/eckler-route-config';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { IResetPassword } from 'src/app/shared/interfaces/reset-password';
import { AuthService } from 'src/app/shared/service/auth.service';
import { PlanService } from 'src/app/shared/service/plan.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _planService: PlanService,
    private _translate: TranslateService) { }

  resetFormGroup: UntypedFormGroup;
  submitted = false;
  resetSucceeded = false;
  slug = '';
  logoToDisplay: any;
  isImageValid = false;
  isLoading = true;

  ngOnInit(): void {
    this.slug = this._planService.getTheSlug(this._route.snapshot.params.slug);

    this.getLogoFile(this.slug);
    const code = this._route.snapshot.params.code;
    const language = this._route.snapshot.params.language;
    this.resetFormGroup = this._formBuilder.group({
      code: [code],
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
      isMfaActive: [true],
      allowMfaDisable: [false],
      mfaCommunicationMethod: ['', Validators.required],
      mobile: ['']
    });

    const lang = language === 'fr-CA' ? LanguageEnum.French : LanguageEnum.English;
    this.setLanguage(lang);
  }

  setLanguage(language:LanguageEnum){
    if (language === LanguageEnum.French){
      this._translate.use('fr_CA');
    } else {
      this._translate.use('en_CA');
    }
  }
  
  checkPasswords() {
    if(!this.resetFormGroup)
      return null;

    const password = this.resetFormGroup.controls.password.value;
    const passwordConfirm = this.resetFormGroup.controls.passwordConfirm.value;

    return password === passwordConfirm ? this.resetFormGroup.controls.passwordConfirm.setErrors(null) : this.resetFormGroup.controls.passwordConfirm.setErrors({ notSame: true });
  }

  reset() {
    this.submitted = true;
    const resetPasswordModel: IResetPassword = Object.assign({}, this.resetFormGroup.value);
    this._auth.resetPassword(resetPasswordModel)
      .subscribe(() => {
        this.resetSucceeded = true;
      }).add(() => this.submitted = false);
  }
  goToLogin() {
    this._router.navigate([EcklerRouteConfig.loginRoute(this.slug)]);
  }

  openTermOfUse() {
    this.dialog.open(TermUseComponent);

  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.logoToDisplay = reader.result;
      this.isImageValid = true;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getLogoFile(slug: string): void {
    this._planService.getPlanLogo(slug).subscribe({
      next: data => {
        this.createImageFromBlob(data);
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        this.isImageValid = false;
      }
    });
  }

}
