import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ILogin } from '../interfaces/login';
import { IResetPassword } from '../interfaces/reset-password';
import { IVerificationFieldResponse } from '../interfaces/verification-field-response';
import { IChangePassword } from '../interfaces/change-password';
import { IPlan } from '../interfaces/plan';
import { IUser } from '../interfaces/user';
import { UserTypeEnum } from '../enum/user-type-enum';
import jwt_decode from 'jwt-decode';
import { IAuthenticateResult } from '../interfaces/authenticate-result';
import { EcklerRouteConfig } from '../eckler-route-config';
import { IJoin } from '../interfaces/join';
import { IMfa } from '../interfaces/mfa';
import { IRefreshTokenModel } from '../interfaces/refresh-token';
import { IForgotPassword } from '../interfaces/forgot-password';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private currentUserSubject = new BehaviorSubject<IUser>(null);
  public currentUser: Observable<IUser>;

  constructor(private _http: HttpClient
  ) {
    if (localStorage.getItem('ecklerUser')) {
      this.currentUserSubject = new BehaviorSubject<IUser>(this.createUserFromAuthenticateResult(JSON.parse(localStorage.getItem('ecklerUser'))));
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  signIn(login: ILogin): Observable<IAuthenticateResult> {
    const userName = login.username.split('@')[0];
    if (localStorage.getItem('ecklerMFAPersitence_' + userName)) {
      login.persistenceToken = localStorage.getItem('ecklerMFAPersitence_' + userName);
    }
    return this._http.post<IAuthenticateResult>(`${environment.mainApiUrl}security/login`, login).pipe(map(ar => {
      if (ar.token && ar.token.length > 0) {
        localStorage.setItem('ecklerUser', JSON.stringify(ar));
        const u = this.createUserFromAuthenticateResult(ar);
        this.currentUserSubject.next(u);
      }
      return ar;
    }));
  }

  validateMfa(mfa: IMfa): Observable<IUser> {
    return this._http.post<any>(`${environment.mainApiUrl}security/mfa`, mfa)
      .pipe(map(ar => {        
        const userName = ar.userName.split('@')[0];
        localStorage.setItem('ecklerUser', JSON.stringify(ar));
        localStorage.setItem('ecklerMFAPersitence_' + userName, ar.persistenceToken);
        const u = this.createUserFromAuthenticateResult(ar);
        this.currentUserSubject.next(u);
        return u;
      }));
  }
  createUserFromAuthenticateResult(ar: IAuthenticateResult) {
    const tokenInfo = this.getDecodedAccessToken(ar.token);
    const u: IUser = {
      userType: UserTypeEnum[tokenInfo.role as keyof typeof UserTypeEnum],
      firstName: ar.firstName,
      language: ar.language,
      primaryColorCode: ar.primaryColorCode,
      secondaryColorCode: ar.secondaryColorCode,
      logoUrl: '',
      token: ar.token,
      refreshToken: ar.refreshToken,
      mailboxes: ar.mailboxes,
      isSecureMailEnabled: ar.isSecureMailEnabled,
      planId: ar.planId,
      planName: ar.planName
    };
    return u;
  }
  signOut() {
    this.currentUserSubject.next(null);
    localStorage.removeItem('ecklerUser');
    location.href = EcklerRouteConfig.loginRoute('');
  }

  updateCurrentUser(ar: IAuthenticateResult) {
    this.currentUserSubject.next(this.createUserFromAuthenticateResult(ar));
  }

  // resetCurrentUser(){
  //   this.currentUserSubject.next(null);
  // }

  forgotPassword(forgotPasswordModel: IForgotPassword) {
    return this._http.post<any>(environment.mainApiUrl + 'security/forgot-password', forgotPasswordModel);
  }

  resetPassword(resetPasswordModel: IResetPassword) {
    return this._http.put<any>(environment.mainApiUrl + 'security/reset', resetPasswordModel);
  }

  verifyMember(slug: string, x: string, validationResponses: IVerificationFieldResponse[]) {
    return this._http.post<any>(`${environment.mainApiUrl}security/verify`, validationResponses, {
      params: new HttpParams()
        .set('slug', slug)
        .set('x', x)
    });
  }

  join(member: IJoin) {
    return this._http.post<any>(`${environment.mainApiUrl}security/join`, member);
  }

  createpasswordresetcode(slug: string, validationResponses: IVerificationFieldResponse[]) {
    return this._http.post<any>(`${environment.mainApiUrl}security/createpasswordresetcode`, validationResponses, {
      params: new HttpParams()
        .set('slug', slug)
    });
  }

  changePassword(changePasswordModel: IChangePassword) {
    return this._http.put<any>(environment.mainApiUrl + 'security/change', changePasswordModel);

  }

  switchAccount(planId: number): Observable<IAuthenticateResult> {

    return this._http.get<IAuthenticateResult>(environment.mainApiUrl + 'security/switch', {
      params: new HttpParams()
        .set('planId', planId)
    })
      .pipe(map(ar => {
        if (ar.token && ar.token.length > 0) {
          localStorage.setItem('ecklerUser', JSON.stringify(ar));
          const u = this.createUserFromAuthenticateResult(ar);
          this.currentUserSubject.next(u);
        }
        return ar;
      }));
  }

  getPlans() {
    return this._http.get<IPlan[]>(environment.mainApiUrl + 'security/plans');

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  refreshToken() {
    const url = environment.mainApiUrl + 'security/refreshtoken';
    return this._http.post<any>(url, {
      'refreshToken': this.currentUserSubject.value.refreshToken
    })
      .pipe(
        tap((refreshToken: IRefreshTokenModel) => {
          const cu = JSON.parse(localStorage.getItem('ecklerUser'));
          cu.token = refreshToken.token;
          cu.refreshToken = refreshToken.refreshToken;
          localStorage.setItem('ecklerUser', JSON.stringify(cu));
          const u = this.createUserFromAuthenticateResult(cu);
          this.currentUserSubject.next(u);
        }),
        catchError(error => {
          this.signOut(); return throwError(error);
        })
      );

  }
}
