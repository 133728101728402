<h2 class="dialogTitle">{{'Dialog.VerficationMethodCreate' | translate}}</h2>
<mat-dialog-content *ngIf="verificationCreationForm">
<form [formGroup]="verificationCreationForm">
    <mat-form-field class="w-100">
        <mat-label>{{'Verification.FieldLabel' | translate}}</mat-label>
        <input matInput formControlName="verificationFieldLabel">
        <mat-error *ngIf="verificationCreationForm.controls.verificationFieldLabel.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Verification.FieldType' | translate}}</mat-label>
          <mat-select required formControlName="fieldType">
           <mat-option *ngFor="let key of verKeys" [value]="key">{{'Enum.VerificationField'| translate:{type:key} }}</mat-option>
          </mat-select>
          <mat-error *ngIf="verificationCreationForm.controls.fieldType.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
   
   </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-flat-button (click)="cancel()" class="cancel-button">
        {{'Actions.Cancel' | translate}}
    </button>
    <button type="button" mat-flat-button (click)="createPlanVerification()" color="accent" [disabled]="!verificationCreationForm.valid">
        {{'Actions.Add' | translate}}
    </button>

</mat-dialog-actions>