import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAdministrator } from '../interfaces/administrator';
import { IBaseResponse } from '../interfaces/system/baseresponse';
import { PagingRequest } from '../interfaces/system/paging-request';
import { IVerificationField } from '../interfaces/verification-field';

@Injectable({
  providedIn: 'root'
})
export class PlanVerificationieldService {

  constructor(private _http: HttpClient) {
  }

  getplanVerificationFields(query: PagingRequest, planId:number) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'planVerificationField', {
      params: new HttpParams()
        .set('planId', planId)
        .set('filter', query.criterias)
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
    });
  }
  getAllPlanVerificationFields(slug: string) {
    return this._http.get<IVerificationField[]>(environment.mainApiUrl + 'security/verificationfields', {
      params: new HttpParams()
        .set('slug', slug)
    });
  }

  checkIfPlanHasMember(planId:number){
    return this._http.get<boolean>(environment.mainApiUrl + 'planVerificationField/checkmember/' + planId);
  }

  createplanVerificationField(verificationField: IVerificationField) {
    return this._http.post<IAdministrator>(environment.mainApiUrl + 'planVerificationField', verificationField);
  }

  updateVerificationField(verificationFieldId: number, verificationField: IVerificationField) {
    return this._http.put(environment.mainApiUrl+'planVerificationField/'+ verificationFieldId, verificationField);
  }

  getplanVerificationField(verificationFieldId: number) {
    return this._http.get<IAdministrator>(environment.mainApiUrl+ 'planVerificationField/' + verificationFieldId);
  }

  deleteVerificationField(verificationFieldId: number) {
    return this._http.delete(environment.mainApiUrl + 'planVerificationField/' + verificationFieldId);
  }
}
