import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeleteItemComponent } from 'src/app/dialog/delete-item/delete-item.component';
import { DeletionTypeEnum } from '../../enum/deletion-type';
import { IDocuments } from '../../interfaces/documents';
import { PagingRequest } from '../../interfaces/system/paging-request';
import { DocumentService } from '../../service/document.service';
import { NotificationService } from '../../service/notification.service';
import { PagingHelperService } from '../../service/paging-helper.service';

@Component({
  selector: 'app-publish',
  templateUrl: './publish-document.component.html',
  providers: [{ provide: PagingHelperService }]

})
export class PublishDocumentComponent implements OnInit, OnDestroy {
  @Input() planId: number;
  listOfDocument: IDocuments[] = [];
  pagingHelpSubs: Subscription;
  criterias = '';
  active: string;
  direction: string;
  loading = false;

  displayedColumns: string[] = ['name', 'date', 'download', 'delete'];
  dataSource = new MatTableDataSource(this.listOfDocument);

  constructor(
    public dialog: MatDialog,
    private _document: DocumentService,
    public _pagingHelper: PagingHelperService,
    private _notification: NotificationService,
    private _translate: TranslateService) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {

    this.pagingHelpSubs = this._pagingHelper.loadMore.subscribe(load => {
      if (load === true) {
        this.getData(this.criterias, this.active, this.direction);

      }
    });
    this.getData(this.criterias);
  }

  getData(criteria: string, active?: string, direction?: string) {
    this.loading = true;
    const query: PagingRequest = {
      criterias: criteria,
      paging: this._pagingHelper.buildPaging(),
      sortBy: active,
      sortDirection: direction
    };
    this._document.getToReview(query, this.planId)
      .subscribe({
        next: res => {
          this.listOfDocument = [...this.listOfDocument, ...res.payload];
          this.dataSource.data = [];
          this.dataSource.data.push(...res.payload);
          this._pagingHelper.updatePaging(res.metadata.total, this.listOfDocument.length);
          this.dataSource.sort = this.sort;
          this.loading = false;
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
  }

  sortData(e: Sort) {
    this.active = e.active;
    this.direction = e.direction;
    this.dataSource.data = [];
    this._pagingHelper.resetPaging();
    this.getData(this.criterias, e.active, e.direction);
  }

  downloadDocument(id: number) {
    this._document.download(id)
      .subscribe({
        next: x => {

          const file = new Blob([x], { type: 'application/pdf' });
          const fileURL = window.URL.createObjectURL(file);
          window.open(fileURL);
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
  }

  publishDocument() {
    this.loading = true;
    this._document.publishAllDocument(this.planId).subscribe({
      next: res => {
        this.loading = false;
        let data = this.dataSource.data;
        data = [];
        this.listOfDocument = [];
        this.dataSource.data = data;
        this._pagingHelper.resetPaging();
        this.getData(this.criterias, this.active, this.direction);
        this._notification.showSuccessMessage(this._translate.instant('Document.SuccessfullyPublished'));
      },
      error: error => {
        this.loading = false;
        this._notification.showErrorMessage(this._translate.instant('Document.FailedPublish'));
      }
    });
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(DeleteItemComponent, {
      width: '350px',
      data: { deletionType: DeletionTypeEnum.document }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._document.deleteDocument(id).subscribe(() => {
          this._notification.showSuccessMessage(this._translate.instant('Message.DeleteSuccess'));
          let data = this.dataSource.data;
          data = [];
          this.listOfDocument = [];
          this.dataSource.data = data;
          this._pagingHelper.resetPaging();
          this.getData(this.criterias, this.active, this.direction);
        }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.pagingHelpSubs.unsubscribe();
  }
}
