<div class="container py-5 mb-2 mt-5">
    <div class="row text-center">
        <div class="col">
            <mat-spinner *ngIf="isImageLoading"></mat-spinner>
            <img [src]="logoToDisplay" class="logo" alt="logo" *ngIf="!isImageLoading && isImageValid">
            <img src="/assets/images/eckler-logo.svg" alt="Eckler" class="p-0 m-0"
                *ngIf="!isImageLoading && !isImageValid">
        </div>
    </div>
    <div class="row justify-content-md-center mt-3">
        <div class="col-12 col-sm-6">
            <mat-card class="p-5">
                <mat-spinner *ngIf="submitted || loading"></mat-spinner>
                <br />
                <div  *ngIf="form && !verified && !resetPasswordRequested">
                    <h4>{{'Login.VerifyID' | translate}}</h4>
                    <p>{{'Login.JoinVerifyID' | translate}}&nbsp;<a [routerLink]="" class="force-link"
                            (click)="openContacttSlug()">{{'Login.PLanAdmin'|
                            translate}}</a></p>
                </div>
                <form *ngIf="form && !verified && !resetPasswordRequested" (ngSubmit)="submit()" [formGroup]="form">
                    <div *ngFor="let question of verificationFields">
                        <app-question [question]="question" [form]="form"></app-question>
                    </div>
                    <div class="form-row">
                        <button mat-flat-button color="accent" [disabled]="!form.valid || submitted">{{
                            'Actions.Continue' | translate }}</button>
                    </div>
                </form>
                <div *ngIf="emailForm && verified && !resetPasswordRequested">
                  <h4>{{'Login.VerifyEmail' | translate}}</h4>
                    <p>{{'Login.JoinVerifyEmail' | translate}}</p>
                </div>
                <form *ngIf="emailForm && verified && !resetPasswordRequested" (ngSubmit)="sendCode()" [formGroup]="emailForm">
                    <mat-form-field class="w-100">
                        <input type="email" matInput placeholder="{{'Common.Email' | translate }}" formControlName="email" required>
                        <mat-error *ngIf="emailForm.controls.email.hasError('required')">{{'Error.Required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="emailForm.controls.email.hasError('email')">{{'Error.EmailInvalid' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <div class="form-row">
                        <button mat-flat-button color="accent" [disabled]="!form.valid || submitted">{{'Actions.Continue' | translate }}</button>
                    </div>
                </form>
                <div *ngIf="resetPasswordRequested">{{'Message.ResetPasswordRequested' | translate}}</div>
            </mat-card>
        </div>
    </div>
</div>
