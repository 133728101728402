import { Component, OnInit } from '@angular/core';
import { IColor } from './shared/interfaces/system/icolor';
import tinycolor from 'tinycolor2';
import { ColorService } from './shared/service/color.service';
import { SignalRService } from './shared/service/signalr.service';
import { HttpClient } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  primaryColorPalette: IColor[] = [];
  accentColorPalette: IColor[] = [];
  subscription: Subscription;
  
  constructor(private _color:ColorService,
    public signalRService: SignalRService,
    private _router:Router,) { 
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.subscription = this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
           this._router.navigated = false;
        }
      }); 

    }

  ngOnInit(): void {
    this._color.primaryColor$.subscribe(c => {
      this.savePrimaryColor(c);

    });
    this._color.accentColor$.subscribe(c => {
      this.saveSecondaryColor(c);

    });
    this.signalRService.startConnection();
  }

  savePrimaryColor(colorTheme:string) {
    this.primaryColorPalette = this.computeColors(colorTheme);

    for (const color of this.primaryColorPalette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  saveSecondaryColor(colorTheme:string) {
    this.accentColorPalette = this.computeColors(colorTheme);

    for (const color of this.accentColorPalette) {
      const key = `--theme-accent-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  computeColors(hex: string): IColor[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
    ];
  }

  getColorObject(value: any, name: string): IColor {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}