import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationMethodEnum } from 'src/app/shared/enum/communication-method';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { IPlanMember } from 'src/app/shared/interfaces/planmember';
import { PagingRequest } from 'src/app/shared/interfaces/system/paging-request';
import { IVerificationField } from 'src/app/shared/interfaces/verification-field';
import { IVerificationFieldResponse } from 'src/app/shared/interfaces/verification-field-response';
import { FormDirtyService } from 'src/app/shared/service/form-dirty.service';
import { PagingHelperService } from 'src/app/shared/service/paging-helper.service';
import { PlanVerificationieldService } from 'src/app/shared/service/plan-verificationield.service';
import { PlanMemberService } from 'src/app/shared/service/planmember.service';
import { QuestionControlService } from 'src/app/shared/service/question-control.service';

@Component({
  selector: 'app-member-creation',
  templateUrl: './member-creation.component.html',
  providers: [{ provide: PagingHelperService }]
})
export class MemberCreationComponent implements OnInit {

  memberCreationForm: UntypedFormGroup;
  languageList = LanguageEnum;
  communicationKeyList = CommunicationMethodEnum;
  langKeys: number[];
  member: IPlanMember;
  planId: number;
  comKeys: number[];
  verificationFields: IVerificationField[] = [];
  criterias = '';
  verificationFieldFG: UntypedFormGroup;

  constructor(public _dialogRef: MatDialogRef<MemberCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { planId: number },
    private _formBuilder: UntypedFormBuilder,
    private _memberService: PlanMemberService,
    private _verificationField: PlanVerificationieldService,
    private PagingHelperService: PagingHelperService,
    private _qcs: QuestionControlService,
    private _dirty: FormDirtyService,
    private _datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.planId = this.data.planId;

    this.getVerificationData();

    const langArray = Object.keys(this.languageList).map(x => +x);
    langArray.splice((langArray.length / 2), (langArray.length / 2));
    this.langKeys = langArray;

  }

  getVerificationData() {
    const query: PagingRequest = {
      criterias: this.criterias,
      paging: this.PagingHelperService.buildPaging(),
      sortBy: null,
      sortDirection: null
    };
    this._verificationField.getplanVerificationFields(
      query, this.planId).subscribe(res => {
      this.verificationFields = res.payload;
      this.initialForm();

      this.verificationFieldFG = this._qcs.toFormGroup(this.verificationFields);
      this.memberCreationForm.valueChanges.subscribe(x => this._dirty.formIsDirty());

    });

  }

  initialForm() {
    this.memberCreationForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      memberId: ['', Validators.required],
      mobile: [''],
      email: ['', Validators.email],
      language: ['', Validators.required],
      optIn: [true]

    });

  }

  save() {

    const member: IPlanMember = Object.assign({}, this.memberCreationForm.value);
    member.planId = this.planId;

    const verification: IVerificationFieldResponse[] =
      Object.keys(this.verificationFieldFG.value)
        .map(key => ({
          planVerificationFieldId: +key,
          response: this.getSafeValue(key)
        }));
    member.verificationFieldResponses = verification.concat();

    this._memberService.createPlanMembers(member).subscribe(result => {
      this._dirty.formIsClean();
      this._dialogRef.close(result);
    });
  }

  getSafeValue(key: string) {
    let v: string = this.verificationFieldFG.value[key];
    if (this.verificationFields.find(vf => vf.id === +key).fieldType === 3) {
      v = this._datePipe.transform(this.verificationFieldFG.value[key].toString(), 'yyyy-MM-dd');
    }
    return v;
  }

  cancel() {
    this._dirty.formIsClean();
    this._dialogRef.close(null);
  }
}
