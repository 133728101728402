import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { JoinComponent } from './login/join/join.component';
import { LoginComponent } from './login/login/login.component';
import { MFAComponent } from './login/mfa/mfa.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { CanDeactivateGuard } from './shared/service/can-deactivate.service';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';

const routes: Routes = [
  { path: '',
    redirectTo: '/login',
    pathMatch: 'full' },

  {

    path: '',
    component: LayoutComponent,

    children: [
      {
        path: 'member',
        loadChildren: () => import('./plan-members/plan-members.module').then(m => m.PlanMembersModule)

      },
      {
        path: 'plan',
        loadChildren: () => import('./plan/plan.module').then(m => m.PlansModule)

      },
      {
        path: 'eckler',
        loadChildren: () => import('./eckler/eckler.module').then(m => m.EcklerModule)

      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'join/:language',
    component: JoinComponent
  },
  {
    path: ':slug/login',
    component: LoginComponent
  },
  {
    path: ':slug/join',
    component: JoinComponent
  },
  {
    path: ':slug',
    redirectTo: ':slug/login',
    pathMatch: 'full'
  },
  {
    path: 'mfa',
    component: MFAComponent
  },
  {
    path: ':slug/forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetpassword/:code/:language',
    component: ResetPasswordComponent
  },
  {
    path: 'changepassword/:code',
    component: ChangePasswordComponent
  },
  { path: '**',
    redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
