import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { PlanService } from 'src/app/shared/service/plan.service';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls:['./mfa.component.scss']
})
export class MFAComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,

    private _notification: NotificationService,
    private _translate:TranslateService,
    private _fb:UntypedFormBuilder,
    private _planService:PlanService) { }

  submitted = false;
  loading = true;
  form!: UntypedFormGroup;
  logoToDisplay:any;
  isImageValid = false;
  slug: string;

  ngOnInit(): void {
    this.slug = this._planService.getTheSlug(this._route.snapshot.params.slug);

    this.getLogoFile(this.slug);
    this.initform();
  }

  initform(){
    this.form = this._fb.group({
      code: ['', Validators.required]
    });

  }

  resendCode(){
    this._notification.showSuccessMessage(this._translate.instant('Login.CodeResentSuccess'));
  }

  submit() {
    const formValues = this.form.getRawValue();
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.logoToDisplay = reader.result;
      this.isImageValid = true;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getLogoFile(slug: string): void {
    this._planService.getPlanLogo(slug).subscribe({
      next: data => {
        this.createImageFromBlob(data);
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        this.isImageValid = false;
      }
    });
  }
}
