import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBatchInvite } from 'src/app/shared/interfaces/batch-invite';
import { PlanMemberService } from 'src/app/shared/service/planmember.service';

@Component({
  selector: 'app-batch-invite',
  templateUrl: './batch-invite.component.html'
})
export class BatchInviteComponent implements OnInit {

  batchInviteForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { planId: number },
    private _fb: UntypedFormBuilder,
    private _service: PlanMemberService,
    public dialogRef: MatDialogRef<BatchInviteComponent>) {
  }

  ngOnInit(): void {
    this.initialForm();
  }

  initialForm() {
    this.batchInviteForm = this._fb.group({
      sendMethod: [1],
      sendTo: [1]
    });
  }

  sendInvitations() {
    if (this.batchInviteForm.dirty && this.batchInviteForm.valid) {
      const invitations: IBatchInvite = Object.assign({}, this.batchInviteForm.value);
      invitations.planId = this.data.planId;
      if (invitations.sendMethod === '2') {
        this.printInvitations(invitations);
      } else {
        this._service.sendBatchInvites(invitations).subscribe({
          next: result => {
            this.dialogRef.close(result.totalSent);
          }
        });
      }
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  printInvitations(invitations: IBatchInvite){
    this._service.printInvitations(invitations)
      .subscribe(x => {
        const newBlob = new Blob([x], { type: 'application/vnd.ms-word' });
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Invitations.docx';
        link.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
          link.remove();
        }, 100);
      });
  }
}
