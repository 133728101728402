import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IPlan } from '../../interfaces/plan';

@Component({
  selector: 'app-co-profile',
  templateUrl: './co-profile.component.html',
  styleUrls: ['./co-profile.component.scss']
})
export class CoProfileComponent{
  @Input() plan: IPlan;
  @Input() parentForm: UntypedFormGroup;

  parentDirty(){
    this.parentForm.markAsDirty();
  }
}