import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContactSlugComponent } from 'src/app/shared/component/contactslug/contactSlug.component';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { IJoin } from 'src/app/shared/interfaces/join';
import { IVerificationField } from 'src/app/shared/interfaces/verification-field';
import { IVerificationFieldResponse } from 'src/app/shared/interfaces/verification-field-response';
import { AuthService } from 'src/app/shared/service/auth.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { PlanVerificationieldService } from 'src/app/shared/service/plan-verificationield.service';
import { PlanService } from 'src/app/shared/service/plan.service';
import { QuestionControlService } from 'src/app/shared/service/question-control.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit {

  x: string;

  constructor(private _verificationFieldService: PlanVerificationieldService,
    private _route: ActivatedRoute,
    private _notification: NotificationService,
    private _qcs: QuestionControlService,
    private _authService: AuthService,
    private _translate: TranslateService,
    private _planService: PlanService,
    public dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _datePipe: DatePipe) { }

  submitted = false;
  loading = true;
  slug = '';
  verificationFields: IVerificationField[] = [];
  form!: UntypedFormGroup;
  emailForm!: UntypedFormGroup;
  isImageLoading = true;
  isImageValid = false;
  logoToDisplay: any;
  verified = false;
  resetPasswordRequested = false;
  language: any;

  ngOnInit(): void {
    this.slug = this._planService.getTheSlug(this._route.snapshot.params.slug);
    this.getLogoFile(this.slug);

    if(this._route.snapshot.params.language) {
      this.language = this._route.snapshot.params.language === 'fr-CA' ? LanguageEnum.French : LanguageEnum.English;
    } else {
      this.language = this._translate.currentLang === 'fr_CA' ? LanguageEnum.French : LanguageEnum.English;
    }
    
    this.setLanguage(this.language);

    this._route.queryParams
      .subscribe( params => {
        this.x=params.x;
      });

    this._verificationFieldService.getAllPlanVerificationFields(this.slug)
      .subscribe({
        next: res => {
          this.verificationFields = [...this.verificationFields, ...res];
          this.form = this._qcs.toFormGroup(res);
          this.loading = false;
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
  }

  setLanguage(language:LanguageEnum){
    if (language === LanguageEnum.French){
      this._translate.use('fr_CA');
    } else {
      this._translate.use('en_CA');
    }
  }

  submit() {
    this.submitted = true;
    const responses: IVerificationFieldResponse[] = [];
    const formValues = this.form.getRawValue();
    for (const key of Object.keys(formValues)) {
      let v: string = formValues[key];
      if (this.verificationFields.find(vf => vf.id === +key).fieldType === 3) {
        v = this._datePipe.transform(formValues[key], 'yyyy-MM-dd');
      } 
      responses.unshift({
        planVerificationFieldId: +key,
        response: v
      });
    }

    this._authService.verifyMember(this.slug, this.x, responses)
      .subscribe({
        next: res => {
          this.emailForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            memberId: [res.id],
            slug: [this.slug],
            responses: [responses]
          });
          this.verified = true;
          this.submitted = false;
        },
        error: error => {
          this._notification.showErrorMessage(this._translate.instant('Message.WrongInfo'));
          this.submitted = false;
        }
      });
  }
  sendCode() {
    this.submitted = true;
    const member: IJoin = Object.assign({}, this.emailForm.value);

    this._authService.join(member).subscribe({
      next: result => {
        this.submitted = false;
        this.resetPasswordRequested = true;
      },
      error: error => {
        this.submitted = false;
        this._notification.showErrorMessage(error);
      }
    });
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.logoToDisplay = reader.result;
      this.isImageValid = true;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getLogoFile(slug: string): void {
    this._planService.getPlanLogo(slug).subscribe({
      next: data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      error: error => {
        this.isImageLoading = false;
        this.isImageValid = false;
      }
    });
  }

  openContacttSlug() {
    this.dialog.open(ContactSlugComponent, {
      width: '700px',
      data:this.slug
    });
  }
}