import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { IUser } from '../interfaces/user';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptor implements HttpInterceptor {

  constructor(private _auth: AuthService, private _notification: NotificationService,
    private authenticationService: AuthService) {
    this._auth.currentUser.subscribe(u => this.currentUser = u);
  }
  currentUser: IUser;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const authToken = req.headers.get('Authorization');

    if (this.currentUser && this.currentUser.token && (authToken === null || authToken === undefined)) {
      req = this.addTokenToRequest(req, this.currentUser.token);
    }

    return next.handle(req).pipe(
      catchError((err) => {
        const errorMsg = err.error?.message || err.error;
        switch (err.status) {
          case 0: {
            break;
          }

          case HttpStatusCode.Locked: {
            this._notification.showErrorMessage('Your account has been locked because there have been too many failed login attempts. If you haven’t tried logging in before, contact your administrator. Otherwise, come back later.');
            break;
          }

          case HttpStatusCode.Unauthorized: {
            return this.handle401Error(req, next);
          }
          case HttpStatusCode.Forbidden: {
            this._notification.showErrorMessage('You cannot do this action');

            break;
          }
          case HttpStatusCode.BadRequest: {
            if (err && err.error) {
              this._notification.showErrorMessage(err.error);
            } else {
              this._notification.showErrorMessage('The request was not successful, please try again');
            }
            break;
          }
          default:
            break;
        }

        return throwError(() => new Error(errorMsg));

      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this._auth.refreshToken().pipe(
        switchMap((u: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(u.token);
          return next.handle(this.addTokenToRequest(request, u.token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(jwt => next.handle(this.addTokenToRequest(request, jwt))));
    }
  }
  private addTokenToRequest(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
