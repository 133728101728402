
     <app-warning *ngIf="hasMember" [message]="hasmembermessage"></app-warning>
     
     <div class="row justify-content-between align-items-center mx-2 mt-3 mb-2">
        <div class="col justify-content-end d-flex" >
        
            <button color="accent" [disabled]="hasMember === true" mat-flat-button (click)="createDialog()" matTooltip="{{'Tooltip.VerificationCreate' | translate}}" matTooltipClass ="tooltip"><mat-icon>post_add</mat-icon>{{'Actions.VerificationCreate' | translate}}</button>
      </div>
      </div>

      <mat-spinner *ngIf="loading"></mat-spinner>
      <mat-table *ngIf="dataSource && dataSource.data.length > 0" [dataSource]="dataSource" class="mat-elevation-z8 mx-2">
        <ng-container matColumnDef="fieldname">
          <mat-header-cell  *matHeaderCellDef class="col"> {{'Verification.FieldLabel' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let verification" class="col"> {{verification.verificationFieldLabel}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fieldtype">
            <mat-header-cell  *matHeaderCellDef class="col"> {{'Verification.FieldType' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let verification" class="col">{{'Enum.VerificationField' | translate:{type:verification.fieldType} }}</mat-cell>
          </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center"> {{'Actions.Delete' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let verification" class="col-1 justify-content-center"> 
            <button [disabled]="hasMember === true" mat-icon-button color="warn" (click)="deleteVerification(verification.id); $event.stopPropagation()"><mat-icon>delete</mat-icon></button></mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="getverificationDetail(row)" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <app-paging-helper></app-paging-helper>
        <app-no-data *ngIf="(!dataSource || dataSource.data.length <= 0) && !loading"></app-no-data>

