<h2 class="dialogTitle"> {{'Contact.Title'|translate}}</h2>
<mat-dialog-content>
    <p>{{'Contact.NeedHelp'| translate}}</p>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <mat-table *ngIf="dataSource && dataSource.data.length > 0" [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="fullName">
        <mat-header-cell  *matHeaderCellDef class="col"> {{'Common.FullName' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let administrator" class="col"> {{administrator.firstName}}&nbsp;{{administrator.lastName}} </mat-cell>
      </ng-container>


      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef class="col"> {{'Common.Email' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let administrator" class="col"> <a [href]="'mailto:' + administrator.email">{{administrator.email}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef class="col-3"> {{'Common.Phone' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let administrator" class="col-3"><a [href]="'tel: 1' + administrator.mobile">{{administrator.mobile | mask: '(000) 000-0000'}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="language">
        <mat-header-cell *matHeaderCellDef class="col-2"> {{'Common.Language' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let administrator" class="col-2"> {{'Enum.Language' | translate:{type:administrator.language} }} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

</mat-dialog-content>
<mat-dialog-actions align="center">
        <button type="button" mat-flat-button color="accent"  mat-dialog-close="false">{{'Actions.Close'|translate}}</button>
    </mat-dialog-actions>
