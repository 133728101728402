import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IUpload } from '../../interfaces/iupload';
import { IUploadFailed } from '../../interfaces/iupload-failed';
import { PagingRequest } from '../../interfaces/system/paging-request';
import { DocumentService } from '../../service/document.service';
import { NotificationService } from '../../service/notification.service';
import { PagingHelperService } from '../../service/paging-helper.service';
import { TitleService } from '../../service/title.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  providers:[{provide: PagingHelperService}]

})
export class UploadComponent implements OnInit, OnDestroy {
  @Input() uploadId: number;
  listOfFailedUpload: IUploadFailed[] = [];
  pagingHelpSubs: Subscription;
  criterias = '';
  active:string;
  direction: string;
  loading = true;
  upload:IUpload;

  displayedColumns: string[] = ['documentName', 'notes'];
  dataSource = new MatTableDataSource(this.listOfFailedUpload);

  constructor(
    private _service:DocumentService,
    public _pagingHelper:PagingHelperService,
    private _notification:NotificationService,
    private _translate:TranslateService,
    private _title:TitleService) {

  }

    @ViewChild(MatSort) sort: MatSort;

    ngOnInit(): void {

      this.pagingHelpSubs = this._pagingHelper.loadMore.subscribe(load => {
        if (load === true) {
          this.getData(this.criterias, this.active, this.direction);

        }

      });

      this._title.updateTitle(this._translate.instant('Title.UplaodDocumentResult'));

      this.getUpload();

    }

    getUpload(){
      this._service.getUpload(this.uploadId).subscribe({
        next:res => {
          this.upload = res;
          if (res.failCount > 0){
            this.getData(this.criterias);

          } else {
            this.loading = false;
          }
        },
        error:error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
    }

    sortData(e: Sort){
      this.active= e.active;
      this.direction = e.direction;
      this.dataSource.data = [];
      this._pagingHelper.resetPaging();
      this.getData(this.criterias, e.active, e.direction);
    }

    getData(criteria: string, active?: string, direction?: string){
      this.loading = true;
      const query: PagingRequest = {
        criterias: criteria,
        paging: this._pagingHelper.buildPaging(),
        sortBy: active,
        sortDirection: direction
      };
      this._service.getUploadfailed(query, this.uploadId)
        .subscribe({
          next: res => {
            this.listOfFailedUpload = [...this.listOfFailedUpload, ...res.payload];
            this.dataSource.data.push(...res.payload);
            this._pagingHelper.updatePaging(res.metadata.total, this.listOfFailedUpload.length);
            this.dataSource.sort = this.sort;
            this.loading = false;
          },
          error: error => {
            this._notification.showErrorMessage(error);
            this.loading= false;
          }
        });
    }

    ngOnDestroy(): void {
      this.pagingHelpSubs.unsubscribe();
    }

}
