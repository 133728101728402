<nav>
    <div class="sidebar-sticky">

        <img src="/assets/images/eckler-logo.svg" *ngIf="!isImageLoading && !isImageValid" alt="logo" class="logo">        
        <img [src]="logoToDisplay" class="logo" alt = "logo" *ngIf="!isImageLoading && isImageValid">
        <div *ngFor="let item of menuItems">
            <ng-container>
                    <a (click)="closeSidebar()" routerLinkActive="active" class="nav-link" [routerLink]="item.link">{{item.title}}</a>
                </ng-container>
                
        </div>
    </div>

</nav>