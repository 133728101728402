import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { IAdministrator } from '../../interfaces/administrator';
import { AdministratorService } from '../../service/adminsitrator.service';
import { NotificationService } from '../../service/notification.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls:['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  dataSource = new MatTableDataSource<IAdministrator>();

  loading = true;
  planId: number;
  displayedColumns: string[] = ['fullName', 'email', 'phone', 'language'];

  constructor(private _administrator: AdministratorService, private _notification: NotificationService, private _translate: TranslateService) { }

  ngOnInit(): void {
    this.getMyAdmin();
  }

  getMyAdmin() {
    this.loading = true;
    this._administrator.getContactAdministrator()
      .subscribe({
        next: res => {
          this.dataSource.data = res;
          this.loading = false;
        },
        error: error => {
          this._notification.showErrorMessage(this._translate.instant('Error.ErrorGettingAdmin'));
          this.loading = false;
        }
      });
  }
}
