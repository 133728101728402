import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeleteItemComponent } from 'src/app/dialog/delete-item/delete-item.component';
import { VerificationMethodCreationComponent } from 'src/app/dialog/verification-method-creation/verification-method-creation.component';
import { VerificationMethodModificationComponent } from 'src/app/dialog/verification-method-modification/verification-method-modification.component';
import { DeletionTypeEnum } from 'src/app/shared/enum/deletion-type';
import { PagingRequest } from 'src/app/shared/interfaces/system/paging-request';
import { IVerificationField } from 'src/app/shared/interfaces/verification-field';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { PagingHelperService } from 'src/app/shared/service/paging-helper.service';
import { PlanVerificationieldService } from 'src/app/shared/service/plan-verificationield.service';
import { FormDirtyService } from '../../service/form-dirty.service';

import { TitleService } from '../../service/title.service';

@Component({
  selector: 'app-verification-method',
  templateUrl: './verification-method.component.html',
  providers:[{provide: PagingHelperService}]

})
export class VerificationMethodComponent implements OnInit, OnDestroy {
  @Input() planId:number;
  selectedVerification: IVerificationField;
  listOfVerification: IVerificationField[] = [];
  pagingHelpSubs: Subscription;
  criterias = '';
  active:string;
  direction: string;
  loading = false;
  dirtySubs:Subscription;
  formDirty = false;
  hasmembermessage= this._translate.instant('Warning.HasMember');

  displayedColumns: string[] = ['fieldname', 'fieldtype', 'delete'];
  dataSource = new MatTableDataSource(this.listOfVerification);
  hasMember:boolean;

  constructor(
    private _title:TitleService,
    private _dirty: FormDirtyService,
    private _verificationField:PlanVerificationieldService,
    public dialog: MatDialog,
    public _pagingHelper:PagingHelperService,
    private _notification:NotificationService,
    private _translate:TranslateService) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.pagingHelpSubs = this._pagingHelper.loadMore.subscribe(load => {
      if (load === true) {
        this.getData(this.criterias, this.active, this.direction);

      }
    });

    this.dirtySubs = this._dirty.formDirty$.subscribe(x => this.formDirty = x);

    this._title.updateTitle(this._translate.instant('Title.VerificationMethod'));
    this.getData(this.criterias);
  }

  getData(criteria: string, active?: string, direction?: string){
    this.loading = true;
    const query: PagingRequest = {
      criterias: criteria,
      paging: this._pagingHelper.buildPaging(),
      sortBy: active,
      sortDirection: direction
    };
    this._verificationField.getplanVerificationFields(query, this.planId)
      .subscribe({
        next: res => {
          this.listOfVerification = [...this.listOfVerification, ...res.payload];
          this.dataSource.data.push(...res.payload);
          this._pagingHelper.updatePaging(res.metadata.total, this.listOfVerification.length);
          this.dataSource.sort = this.sort;
          this.loading = false;
          this.checkIfPlanHasMember();
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading= false;
        }
      });
  }

  checkIfPlanHasMember(){
    this._verificationField.checkIfPlanHasMember(this.planId).subscribe(
      res => {
        this.hasMember = res;
      }
    );
  }

  sortData(e: Sort){
    this.active= e.active;
    this.direction = e.direction;
    this.dataSource.data = [];
    this._pagingHelper.resetPaging();
    this.getData(this.criterias, e.active, e.direction);
  }

  getverificationDetail(row:IVerificationField){
    if(this.hasMember !== true){
      const dialogRef = this.dialog.open(VerificationMethodModificationComponent, {
        width: '550px',
        data: { verificationModify: {
          verificationfield: row,
          hasMember: this.hasMember
        }
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result){
          this._notification.showSuccessMessage(this._translate.instant('Message.UpdatedSuccess'));
          let data = this.dataSource.data;
          data = [];
          this.listOfVerification = [];
          this.dataSource.data = data;
          this._pagingHelper.resetPaging();
        }

        this.getData(this.criterias, this.active, this.direction);
      }
      );
    }

  }

  createDialog(){
    const dialogRef = this.dialog.open(VerificationMethodCreationComponent, {
      width: '550px',
      data: {planId: this.planId}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = this.dataSource.data;
        data.push(result);
        this.dataSource.data = data;
        this._notification.showSuccessMessage(this._translate.instant('Message.CreationSuccess'));
      }
    });
  }

  deleteVerification(id:number){
    const dialogRef = this.dialog.open(DeleteItemComponent, {
      width: '350px',
      data: {deletionType: DeletionTypeEnum.verificationField}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._verificationField.deleteVerificationField(id).subscribe(() => {
          this._notification.showSuccessMessage(this._translate.instant('Message.DeleteSuccess'));
          let data = this.dataSource.data;
          data = [];
          this.listOfVerification = [];
          this.dataSource.data = data;
          this._pagingHelper.resetPaging();
          this.getData(this.criterias, this.active, this.direction);
        }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.pagingHelpSubs.unsubscribe();
    this.dirtySubs.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if(this.formDirty === true){
      $event.returnValue = true;
    }
  }
}
