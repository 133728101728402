<h2 class="dialogTitle"> {{'Dialog.DeleteDialogTitle'|translate}}</h2>
<mat-dialog-content>
    <p> {{'Dialog.DeleteDialogMessage'|translate}}{{'Enum.DeletionType' | translate:{type:deletionType} }}.</p>
    <p>{{'Dialog.CannotBeUndone' | translate}}</p>
    <mat-slide-toggle color="warn" #checkBox checked="false">{{'Dialog.ConfirmToggle' | translate}}</mat-slide-toggle>
</mat-dialog-content>
<mat-dialog-actions align="end">
        <button type="button" mat-flat-button [mat-dialog-close] class="cancel-button">{{'Actions.Cancel'|translate}}</button>
        <button type="button" color="warn" mat-flat-button [mat-dialog-close]="true" [disabled]="!checkBox.checked" >
            {{'Actions.Delete'|translate}}
        </button>
</mat-dialog-actions>
