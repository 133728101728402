import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldTypeEnum } from 'src/app/shared/enum/field-type';
import { IVerificationField } from 'src/app/shared/interfaces/verification-field';
import { FormDirtyService } from 'src/app/shared/service/form-dirty.service';
import { PlanVerificationieldService } from 'src/app/shared/service/plan-verificationield.service';

@Component({
  selector: 'app-verification-method-creation',
  templateUrl: './verification-method-creation.component.html',
  styleUrls: ['./verification-method-creation.component.scss']
})
export class VerificationMethodCreationComponent implements OnInit {

  verificationCreationForm: UntypedFormGroup;

  verificationType= FieldTypeEnum;
  verKeys: number [];
  planId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { planId: number },
    private _fb:UntypedFormBuilder,
    private _dirty:FormDirtyService,
    private _service:PlanVerificationieldService,
    public dialogRef: MatDialogRef<VerificationMethodCreationComponent>) {
  }

  ngOnInit(): void {
    this.planId = this.data.planId;

    const verArray = Object.keys(this.verificationType).map(x => +x);
    verArray.splice((verArray.length/2), (verArray.length/2));
    this.verKeys = verArray;

    this.initialForm();

    this.verificationCreationForm.valueChanges.subscribe(x => this._dirty.formIsDirty()
    );

  }

  initialForm(){
    this.verificationCreationForm = this._fb.group({
      verificationFieldLabel: ['', Validators.required],
      fieldType: ['', Validators.required]
    });
  }

  createPlanVerification(){
    if (this.verificationCreationForm.dirty && this.verificationCreationForm.valid) {
      const fieldTosave: IVerificationField = Object.assign({}, this.verificationCreationForm.value);
      fieldTosave.planId = this.planId;

      this._service.createplanVerificationField(fieldTosave).subscribe({
        next: result => {
          this._dirty.formIsClean();
          this.dialogRef.close(result);
        }
      });
    }
  }

  cancel(){
    this._dirty.formIsClean();
    this.dialogRef.close(null);
  }

}
