import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, mergeMap, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { SecureEmailAddress } from "../interfaces/user";

@Injectable({
    providedIn: 'root'
  })
  export class UserService {
  
    constructor(private _http: HttpClient) {
    }

    getToList(request: string) {
      let content: string = JSON.stringify(request);
         const url = environment.mainApiUrl + 'securemail/getAddressList/' + request; 
        let options: any = {
          observe: "response",
          responseType: "json",
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Accept": "application/json"
          })
        };
        return this._http.request("post", url, options).pipe(mergeMap((response: any) => {
          return of<Array<SecureEmailAddress> | null>(response.body);
        })).pipe(catchError((err: any) => { return <Observable<Array<SecureEmailAddress> | null>><any>throwError(err); }));
      }
}