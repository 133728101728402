<div class="button-list mt-2 d-flex justify-content-center" *ngIf="membermodificationForm">

    <button mat-icon-button color="accent"  (click)="goToList()" class="mx-1"><mat-icon>arrow_back</mat-icon></button>
    <button mat-icon-button color="accent" (click)="updateMember()" [disabled]="!membermodificationForm.valid || !membermodificationForm.dirty" class="mx-1"><mat-icon>save_as</mat-icon></button>
    <button   mat-icon-button color="warn" class="mx-1" (click)="deleteMember()" [disabled]="checkDeleteMemberPossible()"><mat-icon>delete</mat-icon></button>
</div>

<mat-divider></mat-divider>

<h1 class="title d-flex align-items-center justify-content-between"><span>{{member.firstName}}&nbsp;{{member.lastName}}</span>
    <mat-icon class="me-2" *ngIf="member.registered" col or="primary" matTooltip="{{'Member.Registred'|translate}}" matTooltipClass ="tooltip">verified</mat-icon>
    <mat-icon class="me-2" *ngIf="!member.registered && member.invitationSentViaEmail" color="accent" matTooltip="{{'Member.InvitationSent'|translate}}" matTooltipClass ="tooltip">mark_email_read</mat-icon>

</h1>
    
<form class="mx-2" *ngIf="membermodificationForm" [formGroup]="membermodificationForm">
    <mat-slide-toggle *ngIf="usertype !== UserTypeEnum.Member" class="withoutformfield" formControlName="enabled" (change)="makeFormDirty()">{{'Member.Enabled' |translate}}</mat-slide-toggle>
    <mat-form-field class="w-100">
        <mat-label>{{'Common.FirstName' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.FirstName' | translate}}" formControlName="firstName">
        <mat-error *ngIf="membermodificationForm.controls.firstName.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field >
    <mat-form-field  class="w-100">
        <mat-label>{{'Common.LastName' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.LastName' | translate}}" formControlName="lastName">
        <mat-error *ngIf="membermodificationForm.controls.lastName.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="usertype !== UserTypeEnum.Member"  class="w-100 mb-3">
        <mat-label>{{'Common.MemberId' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.MemberId' | translate}}" formControlName="memberId">
        <mat-hint>{{'Warning.MemberID'| translate}}</mat-hint>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>{{'Common.Email' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.Email' | translate}}" formControlName="email">

        <mat-error *ngIf="membermodificationForm.controls.email.hasError('email')">
            {{'Error.EmailInvalid' | translate}}
        </mat-error>
    </mat-form-field>


    <mat-form-field class="w-100">
        <mat-label>{{'Common.Phone' | translate}}</mat-label>
        <input mask="(000) 000-0000"  matInput placeholder="{{'Common.Phone' | translate}}"
            formControlName="mobile">
    </mat-form-field>

    <mat-slide-toggle *ngIf="usertype === UserTypeEnum.Member" class="withoutformfield" (change)="makeFormDirty()" formControlName="sendCodeViaSMS">{{'Member.SendCodeViaSMS '| translate}}</mat-slide-toggle>

    

    <mat-form-field  class="w-100">
        <mat-label>{{'Common.Language' | translate}}</mat-label>
          <mat-select required formControlName="language">
           <mat-option *ngFor="let key of langKeys" [value]="key">{{'Enum.Language'| translate:{type:key} }}</mat-option>
          </mat-select>
          <mat-error *ngIf="membermodificationForm.controls.language.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-slide-toggle (change)="makeFormDirty()" class="withoutformfield" formControlName="optIn">{{'Member.OptIn' |
        translate}}</mat-slide-toggle>
        <br>

    <mat-slide-toggle *ngIf="usertype === UserTypeEnum.Member" class="withoutformfield" (change)="makeFormDirty()" formControlName="electronicDocumentOnly">{{'Member.Electronically'| translate}}</mat-slide-toggle>

   </form>
