import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IChangePassword } from 'src/app/shared/interfaces/change-password';
import { AuthService } from 'src/app/shared/service/auth.service';
import { PlanService } from 'src/app/shared/service/plan.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private _formBuilder: UntypedFormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _planService:PlanService) { }
  changeFormGroup: UntypedFormGroup;
  submitted = false;
  changeSucceeded = false;
  logoToDisplay:any;
  isImageValid:boolean;
  isImageLoading = false;

  ngOnInit(): void {
    this.getLogoFile();
    const memberId = this._route.snapshot.params.memberId;
    this. changeFormGroup = this._formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });
  }

  checkPasswords() {
    if(!this.changeFormGroup)
      return null;

    const password = this.changeFormGroup.controls.newPassword.value;
    const passwordConfirm = this.changeFormGroup.controls.passwordConfirm.value;

    return password === passwordConfirm ? this.changeFormGroup.controls.passwordConfirm.setErrors(null) : this.changeFormGroup.controls.passwordConfirm.setErrors({ notSame: true });
  }

  changePassword() {
    this.submitted = true;
    const changetPasswordModel: IChangePassword = Object.assign({}, this.changeFormGroup.value);
    this._auth.changePassword(changetPasswordModel)
      .subscribe(() => {
        this.changeSucceeded = true;
      }).add(() => this.submitted = false);
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.logoToDisplay = reader.result;
      this.isImageValid = true;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getLogoFile(): void {
    this.isImageLoading = true;
    this._planService.getCurentLogo().subscribe({
      next: data => {        
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      error: error => {
        this.isImageLoading = false;
        this.isImageValid = false;
      }
    });
  }
}
