import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/app/shared/service/document.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-upload-general-document',
  templateUrl: './upload-general-document.component.html'
})
export class UploadGeneralDocumentComponent {

  constructor(public dialogRef: MatDialogRef<UploadGeneralDocumentComponent>,
    private _documentService: DocumentService,
    private _notification: NotificationService,
    private _translate: TranslateService) { }
  @ViewChild('fileInput') fileInput: any;
  uploading = false;
  uploaded = false;
  documentName: string;

  uploadGeneralFile() {
    this.uploading = true;
    const fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      const fileToUpload = fi.files[0];
      this._documentService
        .uploadGeneralFile(fileToUpload, this.documentName)
        .subscribe(() => {
          this._notification.showSuccessMessage(this._translate.instant('Message.UpdatedSuccess'));
          this.uploading = false;
          this.uploaded = true;
        }).add(() => this.uploading = false);
    }
  }

}
