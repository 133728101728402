<h2 class="dialogTitle">{{'Dialog.VerficationMethodModify' | translate}}</h2>
<mat-dialog-content *ngIf="verificationModificationForm">
<form [formGroup]="verificationModificationForm">
    <mat-form-field class="w-100">
        <mat-label>{{'Verification.FieldLabel' | translate}}</mat-label>
        <input matInput formControlName="verificationFieldLabel">
        <mat-error *ngIf="verificationModificationForm.controls.verificationFieldLabel.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Verification.FieldType' | translate}}</mat-label>
          <mat-select required formControlName="fieldType">
           <mat-option *ngFor="let key of verKeys" [value]="key">{{'Enum.VerificationField'| translate:{type:key} }}</mat-option>
          </mat-select>
          <mat-error *ngIf="verificationModificationForm.controls.fieldType.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
   
   </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-flat-button (click)="cancel()">
        {{'Actions.Cancel' | translate}}
    </button>
    <button type="button" mat-flat-button (click)="modifyPlanVerification()" color="accent" [disabled]="!verificationModificationForm.valid">
        {{'Actions.Save' | translate}}
    </button>

</mat-dialog-actions>