<div *ngIf="usertype !== usertypeEnum.Member" class="row justify-content-between align-items-center mx-2 mt-3 mb-2">
  <div class="col justify-content-end d-flex">
    <button mat-flat-button color="accent" (click)="uploadDocument(type)" matTooltip="{{'Tooltip.UploadDocument' | translate}}" matTooltipClass ="tooltip">
      <mat-icon>upload_file</mat-icon>{{'Actions.UploadDocument' | translate}}
    </button>
  </div>
</div>

<mat-spinner *ngIf="loading"></mat-spinner>


<mat-table *ngIf="dataSource && dataSource.data.length >0" [dataSource]="dataSource" class="mat-elevation-z8 mx-2">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> {{'Document.DocumentName' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let document"> {{document.fileName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell class="col-sm-2 col-lg-2 justify-content-center" *matHeaderCellDef> {{'Document.Date' | translate}} </mat-header-cell>
    <mat-cell class=" col-sm-2 col-lg-2 justify-content-center" *matCellDef="let document"> {{document.createdOn | date:'shortDate' }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="download">
    <mat-header-cell class=" col-sm-3 col-lg-2 justify-content-center" *matHeaderCellDef matTooltip="{{'Tooltip.DownlaodDocument' | translate }}" matTooltipClass ="tooltip"> {{'Actions.DownlaodDocument' | translate}} </mat-header-cell>
    <mat-cell class=" col-sm-3 col-lg-2 justify-content-center" *matCellDef="let document"><button mat-icon-button color="accent" (click)="downloadDocument(document.id)">
        <mat-icon>download</mat-icon>
      </button></mat-cell>
  </ng-container>

  <ng-container matColumnDef="delete">
    <mat-header-cell class="col-1 justify-content-center" *matHeaderCellDef matTooltip="{{'Tooltip.DeleteDocument' | translate }}" matTooltipClass ="tooltip"> {{'Actions.Delete' | translate}} </mat-header-cell>
    <mat-cell class="col-1 justify-content-center" *matCellDef="let document"><button mat-icon-button color="accent" (click)="delete(document.id)">
        <mat-icon>delete</mat-icon>
      </button></mat-cell>
  </ng-container>

  <ng-container matColumnDef="unread">
    <mat-header-cell class="col-1 justify-content-center" *matHeaderCellDef
      matTooltip="{{'Tooltip.NewDocument' | translate }}" matTooltipClass ="tooltip"> {{'Actions.Unread' | translate}} </mat-header-cell>
    <mat-cell class="col-1 justify-content-center" *matCellDef="let document">
      <button [hidden]="!document.unread" mat-raised-button color="primary" style="border-radius: 50% !important;">New</button>
      <div [hidden]="document.unread" mat-raised-button>Read</div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<app-paging-helper></app-paging-helper>
<app-no-data *ngIf="!dataSource || dataSource.data.length <= 0"></app-no-data>