export enum ProvinceEnum {
    Ontario = 1,
    Quebec = 6,
    NovaScotia = 8,
    NewBrunswick = 7,
    Manitoba = 5,
    BritishColumbia = 2,
    PrinceEdwardIsland = 9,
    Saskatchewan = 4,
    Alberta = 3,
    NewfoundlandAndLabrador = 10,
    NorthwestTerritories = 11,
    Yukon = 12,
    Nunavut = 13
}
