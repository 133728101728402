export class EcklerRouteConfig {
  //--member
  public static planMemberPortalRoute(): string {
    return 'member/';
  }

  public static memberGeneralDocument(): string{
    return 'member/generalDocument';
  }

  public static memberPerosnaleDocument(): string{
    return 'member/personalDocument';
  }

  public static planmembersConfiguration(): string {
    return 'member/configuration';
  }

  public static planMembersMail(): string {
    return 'member/mail';
  }

  //--- client

  public static planReviewRoute(id: number): string {
    return 'Plan/review/' + id;
  }

  public static planImportRoute(id: number): string {
    return 'Plan/import/' + id;
  }

  public static planCieConfigRoute(): string {
    return 'plan/configuration';
  }

  public static planGeneralInfoRoute(): string {
    return 'plan/general-info';
  }
  public static planAdministratorRoute(): string {
    return 'plan/administrators';
  }

  public static planGeneralDcoument(): string {
    return 'plan/generaldocuments';
  }

  public static planPlanMembersListRoute(): string {
    return 'plan/members';
  }

  public static planPlanMembersDetailsRoute(memberId: number): string {
    return 'plan/member/' + memberId;
  }

  public static planAdminMembersLoginRoute(): string {
    return 'plan/memberLogins';
  }

  public static planPlanMembersMail(): string {
    return 'plan/mail';
  }

  //--- eckler

  public static ecklerReviewRoute(id: number): string {
    return 'eckler/review/' + id;
  }

  public static ecklerImportRoute(id: number): string {
    return 'eckler/import/' + id;
  }

  public static ecklerPlanListRoute(): string {
    return 'eckler/plans';
  }

  public static ecklerPlanDetailRoute(planId: number): string {
    return 'eckler/plan/' + planId;
  }

  public static ecklerCieConfigRoute(): string {
    return 'eckler/configuration';
  }

  public static membersLoginRoute():string{
    return 'eckler/memberLogins';
  }

  public static loginRoute(slug: string):string{
    return '/login';
  }
  public static changePasswordRoute(): string{
    return 'changepassword/';

  }

  public static forgotPasswordRoute(slug: string): string{
    return slug + '/forgotpassword';

  }

  public static joinRoute(slug: string):string{
    return slug + '/join';
  }
}
