import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from './module/material.module';
import { MenuContextComponent } from './menu-context/menu-context.component';
import { FooterComponent } from './footer/footer.component';
import { AddressComponent } from './component/address/address.component';
import { AdministratorListComponent } from './component/administrator-list/adminsitartor-list.component';
import { PagingHelperComponent } from './component/paging-helper/paging-helper.component';
import { CoProfileComponent } from './component/co-profile/co-profile.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdministratorCreationComponent } from '../dialog/administrator-creation/administrator-creation.component';
import { AdministratorModificationComponent } from '../dialog/administrator-modification/administrator-modification.component';
import { DeleteItemComponent } from '../dialog/delete-item/delete-item.component';
import { MemberListComponent } from './component/member-list/member-list.component';
import { MemberCreationComponent } from '../dialog/member-creation/member-creation.component';
import { MemberModificationComponent } from '../dialog/member-modification-component/member-modification-component.component';
import { QuestionComponent } from './component/question/question.component';
import { VerificationMethodCreationComponent } from '../dialog/verification-method-creation/verification-method-creation.component';
import { VerificationMethodModificationComponent } from '../dialog/verification-method-modification/verification-method-modification.component';
import { VerificationMethodComponent } from './component/verification-method/verification-method.component';
import { DocumentsComponent } from './component/documents/documents.component';
import {GenerelInfoMemberComponent} from './component/generel-info-member/generel-info-member.component';
import { ImportMembersComponent } from '../dialog/import-members/import-members.component';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { NoDataComponent } from './component/no-data/no-data.component';
import { WarningComponent } from './component/warning/warning.component';
import { BatchInviteComponent } from '../dialog/batch-invite/batch-invite.component';
import { PrivacyComponent } from './component/privacy/privacy.component';
import { TermUseComponent } from './component/term-use/term-use.component';
import { ContactComponent } from './component/contact/contact.component';
import { ReviewComponent } from './component/review/review.component';
import { ImportComponent } from './component/import/import.component';
import { UploadComponent } from './component/upload/upload.component';
import {PublishDocumentComponent } from './component/publishDocument/publish-document.component';

@NgModule({
    declarations: [
        VerificationMethodComponent,
        VerificationMethodCreationComponent,
        VerificationMethodModificationComponent,
        LayoutComponent,
        MenuContextComponent,
        FooterComponent,
        CoProfileComponent,
        AddressComponent,
        AdministratorListComponent,
        PagingHelperComponent,
        SidebarComponent,
        AdministratorCreationComponent,
        AdministratorModificationComponent,
        DeleteItemComponent,
        MemberListComponent,
        MemberCreationComponent,
        MemberModificationComponent,
        QuestionComponent,
        DocumentsComponent,
        GenerelInfoMemberComponent,
        ImportMembersComponent,
        ConfirmDialogComponent,
        NoDataComponent,
        WarningComponent,
        BatchInviteComponent,
        PrivacyComponent,
        TermUseComponent,
        ContactComponent,
        ReviewComponent,
        ImportComponent,
        UploadComponent,
        PublishDocumentComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        ReactiveFormsModule,
        NgxMaskModule.forChild()
    ],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        NgxMaskModule,
        AddressComponent,
        ReactiveFormsModule,
        AdministratorListComponent,
        CoProfileComponent,
        PagingHelperComponent,
        MemberListComponent,
        MemberCreationComponent,
        QuestionComponent,
        VerificationMethodComponent,
        DocumentsComponent,
        GenerelInfoMemberComponent,
        NoDataComponent,
        BatchInviteComponent,
        WarningComponent,
        PublishDocumentComponent
    ]
})
export class SharedModule { }
