<h2 class="dialogTitle">{{'Dialog.MemberUpload' | translate}}</h2>
<mat-dialog-content>
    <div class="row justify-content-end align-items-center mx-2 mt-3">
        <mat-spinner *ngIf="uploading"></mat-spinner>
        <ng-container *ngIf="!uploading">
            <mat-label>{{'Member.UploadFile'|translate}}</mat-label>
            <div class="d-flex flex-row mt-1">
                <label>
                    <input type="file" id="file" class="d-none" #fileInput (change)="uploadExcelFile()"
                        [accept]="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
                    <a mat-flat-button for="uploadfile" matTooltip="{{'Tooltip.UploadMember'|translate}}" class="upload-icon" matTooltipClass ="tooltip">
                        <mat-icon>add_photo_alternate</mat-icon>
                    </a>
                </label>
            </div>
            <!-- <p *ngIf="uploaded" class="mt-1 text-muted">{{'Member.FileSuccessfullyUploaded'| translate}}</p> -->
        </ng-container>
    </div>
    <!-- <ng-container *ngIf="uploaded && importResult">
        <div class="row mx-2 mt-3">
            <div class="col">
                New Members: {{importResult.successfulInserts}}<br />
                Updates: {{importResult.successfulUpdates}}<br />
                {{'Dialog.FailedImports' | translate}}: {{importResult.failures}}
            </div>
        </div>
        <div class="row mx-2 mt-5" *ngIf="importResult.issues && importResult.issues.length > 0">
            <div class="col">                
                    {{'Dialog.FailedImports' | translate}}
                    <div class="row text-uppercase">
                        <div class="col-2">{{'Dialog.RowIndex' | translate}}</div>
                        <div class="col-10">{{'Dialog.Reason' | translate}}</div>
                    </div>
                    <div class="row" *ngFor="let issue of importResult.issues">
                        <div class="col-2">{{issue.rowIndex}}</div>
                        <div class="col-10">{{'Message.'+issue.reason | translate}}</div>
                    </div>
            </div>
        </div>
    </ng-container> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
    
    <button type="button" mat-flat-button [mat-dialog-close]="null" class="cancel-button">
        {{'Actions.Close' | translate}}
    </button>
    <button type="button" mat-flat-button color="accent" (click)="getTemplate()">
        {{'Actions.GetTemplate' | translate}}
    </button>


</mat-dialog-actions>