import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IImport } from '../../interfaces/iimport';
import { IImportFailed } from '../../interfaces/iimport-failed';
import { PagingRequest } from '../../interfaces/system/paging-request';
import { NotificationService } from '../../service/notification.service';
import { PagingHelperService } from '../../service/paging-helper.service';
import { PlanMemberService } from '../../service/planmember.service';
import { TitleService } from '../../service/title.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  providers:[{provide: PagingHelperService}]

})
export class ImportComponent implements OnInit, OnDestroy {

  listOfFailedImport: IImportFailed[] = [];
  pagingHelpSubs: Subscription;
  criterias = '';
  active:string;
  direction: string;
  loading = true;
  import:IImport;

  displayedColumns: string[] = ['rowIndex', 'notes'];
  dataSource = new MatTableDataSource(this.listOfFailedImport);
  importId:number;

  constructor(
    private _service:PlanMemberService,
    public _pagingHelper:PagingHelperService,
    private _notification:NotificationService,
    private _translate:TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _title:TitleService) { }

    @ViewChild(MatSort) sort: MatSort;

    ngOnInit(): void {

      this._activatedRoute.paramMap.subscribe(paramMap => this.onChangeUrlParameters(paramMap));

      this.pagingHelpSubs = this._pagingHelper.loadMore.subscribe(load => {
        if (load === true) {
          this.getData(this.criterias, this.active, this.direction);

        }
        this._title.updateTitle(this._translate.instant('Title.ImportMemberResult'));

        this.getImport();

      });

    }

    onChangeUrlParameters(paramMap: ParamMap) {

      this.importId = +paramMap.get('id');

      this.getImport();

    }

    getImport(){
      this._service.getImport(this.importId).subscribe({
        next:res => {
          this.import = res;
          if (res.failCount > 0){
            this.getData(this.criterias);

          } else {
            this.loading = false;
          }
        },
        error:error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
    }

    sortData(e: Sort){
      this.active= e.active;
      this.direction = e.direction;
      this.dataSource.data = [];
      this._pagingHelper.resetPaging();
      this.getData(this.criterias, e.active, e.direction);
    }

    getData(criteria: string, active?: string, direction?: string){
      this.loading = true;
      const query: PagingRequest = {
        criterias: criteria,
        paging: this._pagingHelper.buildPaging(),
        sortBy: active,
        sortDirection: direction
      };
      this._service.getImportfailed(query, this.importId)
        .subscribe({
          next: res => {
            this.listOfFailedImport = [...this.listOfFailedImport, ...res.payload];
            this.dataSource.data.push(...res.payload);
            this._pagingHelper.updatePaging(res.metadata.total, this.listOfFailedImport.length);
            this.dataSource.sort = this.sort;
            this.loading = false;
          },
          error: error => {
            this._notification.showErrorMessage(error);
            this.loading= false;
          }
        });
    }

    ngOnDestroy(): void {
      this.pagingHelpSubs.unsubscribe();
    }

}
