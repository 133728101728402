import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUpload } from '../interfaces/iupload';
import { IBaseResponse } from '../interfaces/system/baseresponse';
import { PagingRequest } from '../interfaces/system/paging-request';
import { IUnreadDocuments } from '../interfaces/documents';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private _http: HttpClient) {
  }

  uploadGeneralFile(fileToUpload: any, documentName: string) {
    const formData: FormData = new FormData();
    formData.append(documentName, fileToUpload, fileToUpload.name);
    return this._http.post(environment.mainApiUrl + 'document/general', formData);
  }

  uploadPersonalFile(fileToUpload: any, documentName: string, memberId: number) {
    const formData: FormData = new FormData();
    formData.append(documentName, fileToUpload, fileToUpload.name);
    return this._http.post(environment.mainApiUrl + 'document/personal/' + memberId.toString(), formData);
  }

  download(id: number): Observable<Blob> {
    return this._http.get(`${environment.mainApiUrl}document/download`, {
      params: new HttpParams()
        .set('id', id),
      responseType: 'blob'
    });
  }

  deleteDocument(id: number) {
    return this._http.delete(environment.mainApiUrl + 'document/' + id);
  }

  updateUnreadFlag(id: number) {
    return this._http.put(environment.mainApiUrl + 'document/updateUnreadFlag/' , {
      params: new HttpParams()
        .set('id', id)
    });
  }

  getDocument(query: PagingRequest) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'document', {
      params: new HttpParams()
        .set('filter', query.criterias)
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
        .set('documentType', query.type)
        .set('MemberId', (query.memberId == undefined? 0 : query.memberId))
    });
  }

  processFiles(planId: number) {
    return this._http.get<any>(environment.mainApiUrl + 'document/process', {
      params: new HttpParams()
        .set('planId', planId)
    });
  }

  getUnreadDocumentCount(memberId: number) {
    return this._http.get<IUnreadDocuments>(environment.mainApiUrl + 'document/unreadDocumentCount/' + memberId);
  }

  getUpload(uploadId: number) {
    return this._http.get<IUpload>(environment.mainApiUrl + 'document/upload/' + uploadId);
  }

  getUploadfailed(query: PagingRequest, uploadId: number) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'document/uploadfails/' + uploadId, {
      params: new HttpParams()
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
    });
  }

  getToReview(query: PagingRequest, planId?: number) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'document/toreview', {
      params: new HttpParams()
        .set('planId', planId)
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
    });
  }

  publishAllDocument(planId: number) {
    return this._http.put(environment.mainApiUrl + 'document/publish', null, {
      params: new HttpParams()
        .set('planId', planId)
    });
  }

}