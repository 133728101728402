import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContactSlugComponent } from 'src/app/shared/component/contactslug/contactSlug.component';
import { EcklerRouteConfig } from 'src/app/shared/eckler-route-config';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { UserTypeEnum } from 'src/app/shared/enum/user-type-enum';
import { ILogin } from 'src/app/shared/interfaces/login';
import { IMfa } from 'src/app/shared/interfaces/mfa';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ColorService } from 'src/app/shared/service/color.service';
import { PlanService } from 'src/app/shared/service/plan.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private _formBuilder: UntypedFormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _translate: TranslateService,
    private _planService: PlanService,
    public dialog: MatDialog,
    private _color:ColorService,
    private dialogRef: MatDialogRef<LoginComponent>) {
      _translate.setDefaultLang('en_CA');
    }
  loginFormGroup: UntypedFormGroup;
  loading = true;
  planName = 'Eckler';
  adminEmail = 'info@eckler.com';
  mfaFormGroup: UntypedFormGroup;
  submitted = false;
  slug = '';
  isImageLoading = true;
  isImageValid = false;
  logoToDisplay: any;
  mfa: IMfa;
  loginMessage:string;
  subtitleMessage:string;
  contactUsMessage:string;
  isFrench: boolean = false;

  ngOnInit(): void {
    this.slug = this._planService.getTheSlug(this._route.snapshot.params.slug);

    this.getInfo(this.slug);

    this.getLogoFile(this.slug);

    this.loginFormGroup = this._formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  login() {
    this.submitted = true;
    const loginModel: ILogin = Object.assign({}, this.loginFormGroup.value);
    if (this.slug && this.slug.length > 0) {
      loginModel.slug = this.slug;
    }

    if (this.loginFormGroup.valid) {
      this._auth.signIn(loginModel)
        .subscribe({
          next: ar => { 
            this.setLanguage(ar.language);
            if (ar.token && ar.token.length > 0) {
              this._auth.currentUser.subscribe(u => {
                if (u.userType === UserTypeEnum.SuperUser) {
                  this._router.navigate([EcklerRouteConfig.ecklerPlanListRoute()]);
                } else if (u.userType === UserTypeEnum.Administrator) {
                  this._router.navigate([EcklerRouteConfig.planPlanMembersListRoute()]);
                } else if (u.userType === UserTypeEnum.Member) {
                  this._router.navigate([EcklerRouteConfig.planMemberPortalRoute()]);
                }
              });
            } else {
              this.submitted = false;
              this.mfa = ar.mfa;

              this.mfaFormGroup = this._formBuilder.group({
                code: ['', [Validators.required]],
                mfaPayload: [ar.mfa.mfaPayload],
                slug: [this.slug],
                username: [ar.mfa.username],
                persistence: [false],
                persistenceDays: [],
                isSecureMailEnabled:[ar.isSecureMailEnabled]
              });
            }
          },
          error: error => {
            this.submitted = false;
          }
        });
    }
  }

  getInfo(slug:string){
    const lang = this._translate.getBrowserCultureLang();
    const language = lang === 'fr-CA' ? LanguageEnum.French : LanguageEnum.English;
    this.setLanguage(language);
    this._planService.getPlanInfo(slug, language).subscribe(
      res => {
        this.planName = res.name;
        this.adminEmail =res.contactEmail;
        this._color.updatePrimaryColor(res.primaryColorCode);
        this._color.updateAccentColor(res.secondaryColorCode);
        this.loading = false;
        this.loginMessage = res.loginMessage;
        this.subtitleMessage = res.subTitleMessage;
        this.contactUsMessage = res.contactUsMessage;
        this.setLanguage(res.language === 'French' ? LanguageEnum.French : LanguageEnum.English);
      }

    );
  }

  setLanguage(language:LanguageEnum){
    if (language === LanguageEnum.French){
      this._translate.use('fr_CA');
      this.isFrench = true;
    } else {
      this._translate.use('en_CA');
    }

  }

  submitMfa() {
    this.submitted = true;
    const mfaModel: IMfa = Object.assign({}, this.mfaFormGroup.value);
    this._auth.validateMfa(mfaModel)
      .subscribe({
        next: u => {
          const lang = u.language === 1 ? 'en_CA' : 'fr_CA';
          this._translate.setDefaultLang(lang);
          this._translate.use(lang);
          this.submitted = false;
          if (u.userType === UserTypeEnum.SuperUser) {
            this._router.navigate([EcklerRouteConfig.ecklerPlanListRoute()]);
          } else if (u.userType === UserTypeEnum.Administrator) {
            this._router.navigate([EcklerRouteConfig.planPlanMembersListRoute()]);
          } else if (u.userType === UserTypeEnum.Member) {
            this._router.navigate([EcklerRouteConfig.planMemberPortalRoute()]);
          }
        },
        error: error => {
          this.submitted = false;
        }
      });
  }

  register() {
    this._router.navigate([EcklerRouteConfig.joinRoute(this.slug)]);
  }

  forgotPassword() { 
    this._router.navigate([EcklerRouteConfig.forgotPasswordRoute(this.slug)]);
  }

  close() {
    this.dialogRef.close();
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.logoToDisplay = reader.result;
      this.isImageValid = true;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getLogoFile(slug: string): void {
    this._planService.getPlanLogo(slug).subscribe({
      next: data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      error: error => {
        this.isImageLoading = false;
        this.isImageValid = false;
      }
    });
  }

  openContact() {
    this.dialog.open(ContactSlugComponent,
      { width: '700px',
        data: this.slug });
  }
}
