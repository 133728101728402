import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private translate:TranslateService, private titleService: Title) { }

  private title$: Subject<string> = new Subject<string>();
  title = this.title$.asObservable();

  updateTitle(title:string){
    this.title$.next(title);
    this.titleService.setTitle(title);
  }

}
