import { Component, ComponentFactoryResolver, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeleteItemComponent } from 'src/app/dialog/delete-item/delete-item.component';
import { EcklerRouteConfig } from 'src/app/shared/eckler-route-config';
import { CommunicationMethodEnum } from 'src/app/shared/enum/communication-method';
import { DeletionTypeEnum } from 'src/app/shared/enum/deletion-type';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { IPlanMember } from 'src/app/shared/interfaces/planmember';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { PlanMemberService } from 'src/app/shared/service/planmember.service';
import { UserTypeEnum } from '../../enum/user-type-enum';
import { AuthService } from '../../service/auth.service';
import { FormDirtyService } from '../../service/form-dirty.service';

@Component({
  selector: 'app-generel-info-member',
  templateUrl: './generel-info-member.component.html',
  styleUrls: ['./generel-info-member.component.scss']
})
export class GenerelInfoMemberComponent implements OnInit, OnDestroy {
  membermodificationForm:UntypedFormGroup;
  @Input() member:IPlanMember;

  languageList = LanguageEnum;
  communicationKeyList = CommunicationMethodEnum;
  comKeys: number[];
  langKeys: number[];
  usertype: UserTypeEnum;
  UserTypeEnum = UserTypeEnum;
  authSubs:Subscription;
  currentMemberId: number;

  constructor(private _formBuilder:UntypedFormBuilder,
    private _member:PlanMemberService,
    private _auth:AuthService,
    private _router:Router,
    private _notification:NotificationService,
    private _translate: TranslateService,
    public dialog:MatDialog,
    private _dirty:FormDirtyService) { }

  ngOnInit(): void {
    this.authSubs = this._auth.currentUser.subscribe(x => {
      this.usertype = x.userType;
    });

    if (this.usertype === this.UserTypeEnum.Member){
      this._member.getMyPlanMember().subscribe(res => {
        this.currentMemberId = res.id;
      });
    }
    this.finalizePageInit();

    this.membermodificationForm.controls.language.valueChanges.subscribe(
      x => {
        if(this.currentMemberId === this.member.id){
          switch(x){
            case LanguageEnum.English:
              this._translate.use('en_CA');
              break;
            case LanguageEnum.French:
              this._translate.use('fr_CA');
              break;
            default:
              break;
          }
        }

      }
    );

  }

  finalizePageInit(){

    const langArray:number[] = Object.keys(this.languageList).map(x => +x);
    langArray.splice((langArray.length/2), (langArray.length/2));
    this.langKeys = langArray;

    this.initialForm();

  }

  initialForm() {
    this.membermodificationForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobile: [''],
      email: ['', Validators.email],
      language: ['', Validators.required],
      optIn: [''],
      enabled:[''],
      memberId: [{value: this.member.memberId,
        disabled: true}],
      sendCodeViaSMS: [''],
      electronicDocumentOnly: ['']
    });
    this.setFormValue();
  }

  setFormValue() {
    if(this.member){
      this.membermodificationForm.patchValue({
        firstName: this.member.firstName,
        lastName: this.member.lastName,
        mobile: this.member.mobile,
        email: this.member.email,
        language: this.member.language,
        optIn: this.member.optIn,
        enabled:this.member.enabled,

        sendCodeViaSMS: this.member.sendCodeViaSMS,
        electronicDocumentOnly: this.member.electronicDocumentOnly
      });
    }
  }

  goToList(){
    this._router.navigateByUrl(EcklerRouteConfig.planPlanMembersListRoute());

  }

  goToPortal(){
    this._router.navigateByUrl(EcklerRouteConfig.planMemberPortalRoute());

  }

  changePassword(){
    this._router.navigateByUrl(EcklerRouteConfig.changePasswordRoute());

  }

  makeFormDirty(){
    this.membermodificationForm.markAsDirty();
  }

  updateMember(){
    if(this.membermodificationForm.valid && this.membermodificationForm.dirty){
      const member: IPlanMember = Object.assign({}, this.member, this.membermodificationForm.value);
      this._member.updatePlanMember(member.id, member).subscribe({
        next: res => {
          this.member.firstName = this.membermodificationForm.controls.firstName.value;
          this.member.lastName = this.membermodificationForm.controls.lastName.value;

          this._notification.showSuccessMessage(this._translate.instant('Message.UpdatedSuccess'));
          this.membermodificationForm.markAsPristine();
          this._dirty.formIsClean();
        },
        error: error => {
          this._notification.showErrorMessage(error);
        }
      });
    }
  }

  deleteMember(){
    const dialogRef = this.dialog.open(DeleteItemComponent, {
      width: '350px',
      data: { deletionType: DeletionTypeEnum.memberPlan }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._member.deletePlanMember(this.member.id).subscribe({
          next: () => {
            this._dirty.formIsClean();
            this._notification.showSuccessMessage(this._translate.instant('Message.DeleteSuccess'));
            this.goToList();
          },
          error: error => {
            this._notification.showErrorMessage(error);
          }
        });
      }
    });
  }

  checkDeleteMemberPossible(){
    return !!this.membermodificationForm.dirty;
  }

  ngOnDestroy(): void {
    this.authSubs.unsubscribe();
  }

}
