<div class="container py-5 mb-2 mt-5">
  <div class="row text-center">
    <mat-spinner *ngIf="submitted || isImageLoading || loading"></mat-spinner>
    <br />
  </div>
  <div class="row justify-content-md-around mt-3" *ngIf="!loading">
    <div class="col-12 col-lg-3">
      <img [src]="logoToDisplay" class="logo" alt="logo" *ngIf="!isImageLoading && isImageValid">
      <img src="/assets/images/eckler-logo.svg" alt="Eckler" class="p-0 m-0" *ngIf="!isImageLoading && !isImageValid">
    </div>
    <div class="col-12 col-lg-9">
      <!-- <h1 class="title" *ngIf="!loginMessage">{{'Login.Welcome1'|translate:{PlanName: slug} }}</h1> -->
      <h1 class="title" *ngIf="loginMessage">{{loginMessage}}</h1>

      <!-- <p *ngIf="!subtitleMessage">{{'Login.Welcome2'|translate:{PlanName: slug} }}</p> -->
      <p *ngIf="subtitleMessage">{{subtitleMessage}}</p>
      
      <mat-card class="p-5">
  
        <ng-container *ngIf="!mfa">
          <h1>{{ 'Common.Login' | translate }}</h1>
        <form [formGroup]="loginFormGroup" #formDirective="ngForm" class="row justify-content-between align-items-stretch" (ngSubmit)="login()">
          <div class="col-12 col-sm-5 d-flex justify-content-between flex-column">
            <div *ngIf="isFrench">
              <p>{{'Login.Register' | translate}}&nbsp;</p>
            </div>
            <div *ngIf="!isFrench">
            <p>{{'Login.Register' | translate}}&nbsp;<a class="force-link" [routerLink]=""
                (click)="openContact()">{{'Login.PLanAdmin'|
                translate}}</a></p>
            </div>
            <!-- <a class="buttonregister mb-3 mb-sm-0" (click)="register()" color="accent" mat-flat-button style="text-decoration-line: none;">{{ 'Actions.Register' |
              translate }}</a> -->
              <button mat-flat-button class="buttonregister mb-3 mb-sm-0" color="accent" (click)="register()">{{ 'Actions.Register' |
                translate }}</button>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field class="w-100">
              <input type="text" matInput placeholder="{{'Common.Email' | translate }}" formControlName="username"
                required>
              <mat-error *ngIf="loginFormGroup.controls.username.hasError('required')">{{'Error.Required' | translate}}
              </mat-error>
              <mat-error *ngIf="loginFormGroup.controls.username.hasError('email')">{{'Error.EmailInvalid' | translate}}
              </mat-error>

            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput placeholder="{{'Common.Password' | translate }}" type="password"
                formControlName="password" required>
              <mat-error *ngIf="loginFormGroup.controls.password.hasError('required')">{{'Error.Required' | translate}}
              </mat-error>

            </mat-form-field>
            <button mat-flat-button color="accent" [disabled]="!loginFormGroup.valid || submitted">{{ 'Actions.Login' |
              translate }}</button>
            <div style="float: right;">
              <button mat-flat-button color="accent" (click)="forgotPassword()">{{ 'Actions.ForgotPassword' |
                translate }}</button>
            </div>
          </div>
        </form>
        </ng-container>


        <form *ngIf="mfaFormGroup && mfa" (ngSubmit)="submitMfa()" [formGroup]="mfaFormGroup">          
          <h1>{{'Common.MFAAuthentication' | translate }}</h1>
          <p>{{'Login.CodeText' | translate }}</p>
          <mat-form-field class="w-100">
            <input matInput required placeholder="{{'Login.EnterCode' | translate}}" formControlName="code">
          </mat-form-field>
          <mat-slide-toggle class="withoutformfield" formControlName="persistence">{{'Login.Persistence' |translate}}</mat-slide-toggle>
          <mat-form-field class="w-100" *ngIf="mfaFormGroup.value.persistence">
            <input matInput type="number" max="7" min="1" placeholder="{{'Login.PersistenceDays' | translate}}" formControlName="persistenceDays">
            <mat-hint>
              {{'Login.PersistenceHint' | translate}}
          </mat-hint>
          </mat-form-field>
          <div class="form-row">
            <button mat-flat-button class="mt-4" color="accent" [disabled]="!mfaFormGroup.valid || submitted">{{ 'Actions.Continue'
              | translate }}</button>
          </div>
        </form>
      </mat-card>
      <p *ngIf="contactUsMessage" innerHTML="{{contactUsMessage}}"></p>

    </div>
  </div>
</div>