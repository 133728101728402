import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAdministrator } from '../interfaces/administrator';
import { IBaseResponse } from '../interfaces/system/baseresponse';
import { PagingRequest } from '../interfaces/system/paging-request';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

  constructor(private _http: HttpClient) {
  }

  getAdministrators(query: PagingRequest, planId:number) {
    return this._http.get<IBaseResponse>(environment.mainApiUrl + 'administrator', {
      params: new HttpParams()
        .set('planId', planId)
        .set('filter', query.criterias)
        .set('offset', query.paging.offset)
        .set('limit', query.paging.limit)
        .set('sortBy', query.sortBy)
        .set('sortDirection', query.sortDirection)
    });
  }

  getContactAdministrator(){
    return this._http.get<IAdministrator[]>(environment.mainApiUrl + 'administrator/contact');
  }

  getViaSlugAdministrators(slug:string) {
    return this._http.get<IAdministrator[]>(environment.mainApiUrl + 'administrator/viaslug/' + slug);
  }

  getAdministrator(administratorId: number) {
    return this._http.get<IAdministrator>(environment.mainApiUrl+ 'administrator/' + administratorId);
  }

  createAdministrator(administrator: IAdministrator) {
    return this._http.post<IAdministrator>(environment.mainApiUrl + 'administrator', administrator);
  }

  updateAdministrator(administratorId: number, administrator: IAdministrator) {
    return this._http.put(environment.mainApiUrl+'administrator/'+ administratorId, administrator);
  }

  inviteAdministrator(administratorId: number) {
    return this._http.put(environment.mainApiUrl+ 'administrator/invite/'+ administratorId, null);
  }

  deleteAdministrator(administratorId: number) {
    return this._http.delete(environment.mainApiUrl + 'administrator/' + administratorId);
  }

  getMyAdmin() {
    return this._http.get<IAdministrator>(environment.mainApiUrl + 'administrator/my');
  }

  makeAdministratorMainContact(administratorId: number, planId:number) {
    return this._http.put(`${environment.mainApiUrl}administrator/${administratorId}/plan/${planId}/maincontact`, null);
  }
}