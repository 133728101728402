<h2 class="dialogTitle">{{'Dialog.MemberCreation' | translate}}</h2>
<mat-dialog-content *ngIf="memberCreationForm">
    <form [formGroup]="memberCreationForm">
        <mat-form-field class="w-100">
            <mat-label>{{'Common.FirstName' | translate}}</mat-label>
            <input required matInput placeholder="{{'Common.FirstName' | translate}}" formControlName="firstName">
            <mat-error *ngIf="memberCreationForm.controls.firstName.hasError('required')">
                {{'Error.Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>{{'Common.LastName' | translate}}</mat-label>
            <input required matInput placeholder="{{'Common.LastName' | translate}}" formControlName="lastName">
            <mat-error *ngIf="memberCreationForm.controls.lastName.hasError('required')">
                {{'Error.Required' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>{{'Common.MemberId' | translate}}</mat-label>
            <input required matInput placeholder="{{'Common.MemberId' | translate}}" formControlName="memberId">
            <mat-error *ngIf="memberCreationForm.controls.memberId.hasError('required')">
                {{'Error.Required' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>{{'Common.Email' | translate}}</mat-label>
            <input  matInput placeholder="{{'Common.Email' | translate}}" formControlName="email">
            <mat-error *ngIf="memberCreationForm.controls.email.hasError('email')">
                {{'Error.EmailInvalid' | translate}}
            </mat-error>
        </mat-form-field>


        <mat-form-field class="w-100">
            <mat-label>{{'Common.Phone' | translate}}</mat-label>
            <input mask="(000) 000-0000" matInput placeholder="{{'Common.Phone' | translate}}"
                formControlName="mobile">
            
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>{{'Common.Language' | translate}}</mat-label>
            <mat-select required formControlName="language">
                <mat-option *ngFor="let key of langKeys" [value]="key">{{'Enum.Language'| translate:{type:key} }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="memberCreationForm.controls.language.hasError('required')">
                {{'Error.Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-slide-toggle class="withoutformfield" formControlName="optIn">{{'Member.OptIn' |
            translate}}</mat-slide-toggle>


    </form>
    <ng-container *ngIf="verificationFieldFG">
        <form [formGroup]="verificationFieldFG">
            <ng-container *ngFor="let question of verificationFields">
                <app-question [form]="verificationFieldFG" [question]="question"></app-question>
            </ng-container>
        </form>
    </ng-container>

</mat-dialog-content>
<mat-dialog-actions *ngIf="memberCreationForm" align="end">
    <button type="button" mat-flat-button (click)="cancel()" class="cancel-button">
        {{'Actions.Cancel' | translate}}
    </button>
    <button type="button" mat-flat-button (click)="save()" color="accent"
        [disabled]="!memberCreationForm.valid || !verificationFieldFG.valid ">
        {{'Actions.Add' | translate}}
    </button>

</mat-dialog-actions>