
<div *ngIf="plan && parentForm">
<form class="mt-3 mx-2" *ngIf="plan" [formGroup]="parentForm">
<mat-form-field class="w-100">
    <mat-label>{{'Plan.PlanName' | translate}}</mat-label>
    <input required matInput placeholder="{{'Plan.PlanName' | translate}}" formControlName="name">
    <mat-error *ngIf="parentForm.controls.name.hasError('required')">
        {{'Error.Required' | translate}}
    </mat-error>
</mat-form-field>
<mat-form-field *ngIf="plan.id !== 1" class="w-100 mb-3">
    <mat-label>{{'Plan.Slug' | translate}}</mat-label>
        <input matInput formControlName="slug">
        <span matSuffix>.eckler.com</span>
        <mat-hint>{{'Warning.SlugDisabled' | translate}}</mat-hint>

</mat-form-field>

</form>


</div>

<app-no-data *ngIf="!plan"></app-no-data>
