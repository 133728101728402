import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  private primaryColor:BehaviorSubject<string> = new BehaviorSubject<string>('#2b6178');
  private accentColor:BehaviorSubject<string> = new BehaviorSubject<string>('#48c0b5');

  primaryColor$ = this.primaryColor.asObservable();
  accentColor$ = this.accentColor.asObservable();

  constructor() { }

  updatePrimaryColor(color:string){
    this.primaryColor.next(color);
  }

  updateAccentColor(color:string){
    this.accentColor.next(color);
  }

}
