import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationMethodEnum } from 'src/app/shared/enum/communication-method';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { IPlanMember } from 'src/app/shared/interfaces/planmember';
import { FormDirtyService } from 'src/app/shared/service/form-dirty.service';
import { PlanMemberService } from 'src/app/shared/service/planmember.service';

@Component({
  selector: 'app-member-modification-component',
  templateUrl: './member-modification-component.component.html',
  styleUrls: ['./member-modification-component.component.scss']
})
export class MemberModificationComponent implements OnInit {

  communicationKeyList = CommunicationMethodEnum;
  membermodificationForm: UntypedFormGroup;
  languageList= LanguageEnum;
  langKeys: number[];
  member: IPlanMember;
  comKeys: number[];

  constructor(public _dialogRef: MatDialogRef<MemberModificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { member: IPlanMember },
    private _formBuilder: UntypedFormBuilder,
    private _memberService: PlanMemberService,
    private _dirty:FormDirtyService) { }

  ngOnInit(): void {
    this.member = this.data.member;

    const langArray = Object.keys(this.languageList).map(x => +x);
    langArray.splice((langArray.length/2), (langArray.length/2));
    this.langKeys = langArray;

    this.initialForm();

    this.membermodificationForm.valueChanges.subscribe(x => this._dirty.formIsDirty()
    );

  }
  initialForm() {
    this.membermodificationForm = this._formBuilder.group({
      firstName: [this.member.firstName, Validators.required],
      lastName: [this.member.lastName, Validators.required],
      mobile: [this.member.mobile],
      email: [this.member.email, Validators.email],
      language: [this.member.language, Validators.required],
      optIn: [this.member.optIn],
      enabled:[this.member.enabled],
      memberId: [{value:this.member.memberId,
        disabled:true}]
    });

  }

  makeFormDirty(){
    this.membermodificationForm.markAsDirty();
  }

  save() {
    const member: IPlanMember = Object.assign({}, this.member, this.membermodificationForm.value);
    this._memberService.updatePlanMember(member.id, member).subscribe(result => {
      this._dirty.formIsClean();
      this._dialogRef.close(true);
    });
  }

  cancel(){
    this._dirty.formIsClean();
    this._dialogRef.close(null);
  }
}
