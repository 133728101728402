<h2 class="dialogTitle"> {{'Dialog.FormNotSave'|translate}}</h2>
<mat-dialog-content>
    <p>{{'Dialog.ConfirmMessage' | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
        <button type="button" mat-flat-button  [mat-dialog-close]="false" class="cancel-button">{{'Actions.Goback'|translate}}</button>
        <button type="button" mat-flat-button color="accent" [mat-dialog-close]="true" >
            {{'Actions.ContinueandLose'|translate}}
        </button>
</mat-dialog-actions>
