import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { IAdministrator } from 'src/app/shared/interfaces/administrator';
import { AdministratorService } from 'src/app/shared/service/adminsitrator.service';
import { FormDirtyService } from 'src/app/shared/service/form-dirty.service';

@Component({
  selector: 'app-administrator-modification',
  templateUrl: './administrator-modification.component.html',
  styleUrls: ['./administrator-modification.component.scss']
})
export class AdministratorModificationComponent implements OnInit {

  administratormodificationForm: UntypedFormGroup;
  languageList= LanguageEnum;
  langKeys: number [];
  administrator: IAdministrator;
  currentAdminId:number;

  constructor(public _dialogRef: MatDialogRef<AdministratorModificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { administrator: IAdministrator },
    private _formBuilder: UntypedFormBuilder,
    private _administratorService: AdministratorService,
    private _dirty:FormDirtyService, private _translate:TranslateService) { }

  ngOnInit(): void {
    this.administrator = this.data.administrator;
    const langArray = Object.keys(this.languageList).map(x => +x);
    langArray.splice((langArray.length/2), (langArray.length/2));
    this.langKeys = langArray;

    this._administratorService.getMyAdmin().subscribe(res => {
      this.currentAdminId = res.id;
    });

    this.initialForm();

    this.administratormodificationForm.valueChanges.subscribe(x => this._dirty.formIsDirty()
    );

    this.administratormodificationForm.controls.language.valueChanges.subscribe(
      x => {
        if(this.currentAdminId === this.administrator.id){
          switch(x){
            case LanguageEnum.English:
              this._translate.use('en_CA');
              break;
            case LanguageEnum.French:
              this._translate.use('fr_CA');
              break;
            default:
              break;
          }
        }

      }
    );

  }
  initialForm() {
    this.administratormodificationForm = this._formBuilder.group({
      firstName: [this.administrator.firstName, Validators.required],
      lastName: [this.administrator.lastName, Validators.required],
      mobile: [this.administrator.mobile, Validators.required],
      email: [{value:this.administrator.email,
        disabled: true }],
      language: [this.administrator.language]
    });
  }

  save() {
    const administartor: IAdministrator = Object.assign({}, this.administrator, this.administratormodificationForm.value);
    this._administratorService.updateAdministrator(administartor.id, administartor).subscribe(result => {
      this._dirty.formIsClean();
      this._dialogRef.close(true);
    });
  }

  cancel(){
    this._dirty.formIsClean();
    this._dialogRef.close(null);
  }

}
