import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private sidebarSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sidebarSubject = this.sidebarSubject$.asObservable();
  private rightSideBarSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  rightSibarToggle = this.rightSideBarSubject$.asObservable();
  private sideBarUpdate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sidebarUpdate = this.sideBarUpdate$.asObservable();

  constructor() { }

  closeSidebar() {
    this.sidebarSubject$.next(true);
  }

  sidebarupdate(){
    this.sideBarUpdate$.next(true);
  }

  tooglerightSideBar() {
    if (this.rightSideBarSubject$.value === true){
      this.rightSideBarSubject$.next(false);
    }
    if (this.rightSideBarSubject$.value === false){
      this.rightSideBarSubject$.next(true);
    }
    this.closeSidebar();
  }

  closeRightSideBar(){
    this.rightSideBarSubject$.next(false);
  }

}