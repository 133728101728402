<h2 class="dialogTitle">{{'Dialog.AdministratorCreation' | translate}}</h2>
<mat-dialog-content *ngIf="administratorCreationForm">
<form [formGroup]="administratorCreationForm">
    <mat-form-field class="w-100">
        <mat-label>{{'Common.FirstName' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.FirstName' | translate}}" formControlName="firstName">
        <mat-error *ngIf="administratorCreationForm.controls.firstName.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Common.LastName' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.LastName' | translate}}" formControlName="lastName">
        <mat-error *ngIf="administratorCreationForm.controls.lastName.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>{{'Common.Phone' | translate}}</mat-label>
        <input mask="(000) 000-0000" required matInput placeholder="{{'Common.Phone' | translate}}"
            formControlName="mobile">
        <mat-error *ngIf="administratorCreationForm.controls.mobile.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Common.Email' | translate}}</mat-label>
        <input required matInput placeholder="{{'Common.Email' | translate}}" formControlName="email">
        <mat-error *ngIf="administratorCreationForm.controls.email.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
        <mat-error *ngIf="administratorCreationForm.controls.email.hasError('email')">
            {{'Error.EmailInvalid' | translate}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>{{'Common.Language' | translate}}</mat-label>
          <mat-select required formControlName="language">
           <mat-option *ngFor="let key of langKeys" [value]="key">{{'Enum.Language'| translate:{type:key} }}</mat-option>
          </mat-select>
    </mat-form-field>
   </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-flat-button (click)="cancel()" class="cancel-button">
        {{'Actions.Cancel' | translate}}
    </button>
    <button type="button" mat-flat-button (click)="save()"  color="accent" [disabled]="!administratorCreationForm.valid">
        {{'Actions.Save' | translate}}
    </button>

</mat-dialog-actions>