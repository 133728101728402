<div class="container py-5 mb-2 mt-5">
    <div class="row text-center">
        <div class="col d-flex justify-content-center">
            <img src="/assets/images/eckler-logo.svg" *ngIf="!isImageLoading && !isImageValid" alt="logo" class="p-0 m-0">
            <img [src]="logoToDisplay" *ngIf="!isImageLoading && isImageValid" alt="logo" class="logo p-0 m-0" /> 
        </div>
    </div>
    <div class="row justify-content-md-center mt-3">
        <div class="col-12 col-sm-6">
            <mat-card class="p-5" *ngIf="!changeSucceeded">
                <div *ngIf="submitted">
                    <mat-spinner diameter="25" strokeWidth="1" class="float-left mr-2"></mat-spinner>
                    {{ 'Message.PleaseWait' | translate }}
                </div>
                <br />
                <h4>{{ 'Common.SetPassword' | translate }}</h4>
                <form [formGroup]="changeFormGroup" (ngSubmit)="changePassword()">

                    <mat-form-field class="w-100">
                        <input placeholder="{{'Common.OldPassLabel' | translate}}" matInput
                            formControlName="oldPassword"  required
                            type="password" tabindex="1" />
                        <mat-error *ngIf="changeFormGroup.controls.oldPassword.hasError('required')">
                            {{'Error.Required' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mb-5" >
                        <input matInput minlength="8" #newpass placeholder="{{'Common.Password' | translate }}" type="password"
                            formControlName="newPassword" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&_-])[A-Za-z\d!@#$%^&_-]{8,}$">
                            <mat-hint>{{'Login.PasswordRequirement' | translate}}</mat-hint>
                            <mat-hint align="end">{{newpass.value?.length || 0}}/8</mat-hint>
                            <mat-error *ngIf="changeFormGroup.controls.newPassword.hasError('required')">
                                {{'Error.Required' | translate}}</mat-error>
                                <mat-error *ngIf="changeFormGroup.controls.newPassword.hasError('minlength')">
                                    {{'Error.PassMinlegth' | translate}}</mat-error>
                                    <mat-error *ngIf="changeFormGroup.controls.newPassword.hasError('pattern')">
                                        {{'Error.PassPattern' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mb-5">
                        <input matInput minlength="8" #confirm placeholder="{{'Common.Password' | translate }}" type="password"
                            formControlName="passwordConfirm" required (input)="checkPasswords()"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&_-])[A-Za-z\d!@#$%^&_-]{8,}$">
                        <mat-hint align="end">{{confirm.value?.length || 0}}/8</mat-hint>
                        <mat-hint>{{'Login.PasswordRequirement' | translate}}</mat-hint>
                        <mat-error *ngIf="changeFormGroup.controls.passwordConfirm.hasError('required')">
                            {{'Error.Required' | translate}}</mat-error>
                        <mat-error *ngIf="changeFormGroup.controls.passwordConfirm.hasError('minlength')">
                            {{'Error.PassMinlegth' | translate}}</mat-error>
                        <mat-error *ngIf="changeFormGroup.controls.passwordConfirm.hasError('pattern')">
                            {{'Error.PassPattern' | translate}}</mat-error>
                        <mat-error *ngIf="changeFormGroup.controls.passwordConfirm.hasError('notSame')">
                            {{'Error.PasswordNotMatch' | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-flat-button color="accent" [disabled]="!changeFormGroup.valid || submitted">{{
                        'Actions.SetPassword' | translate }}</button>
                </form>
            </mat-card>
            <mat-card *ngIf="changeSucceeded">
                <h4>{{ 'Common.ChangeSucceeded' | translate }}</h4>
                <p>{{ 'Message.ChangeSucceeded' | translate }}</p>
                <a type="button" mat-flat-button color="accent" routerLink="/login">{{ 'Actions.Login' | translate
                }}</a>
            </mat-card>            
        </div>
    </div>
</div>