import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EcklerRouteConfig } from 'src/app/shared/eckler-route-config';
import { IForgotPassword } from 'src/app/shared/interfaces/forgot-password';
import { AuthService } from 'src/app/shared/service/auth.service';
import { PlanService } from 'src/app/shared/service/plan.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPswdForm: UntypedFormGroup;
  submitted = false;
  forgotPwdSucceeded = false;
  logoToDisplay:any;
  isImageValid:boolean;
  isImageLoading = false;
  isRequesting = false;
  errors: string;
  success: string;
  slug = '';
  language: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _planService:PlanService,
    private _router: Router,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>) { }

  ngOnInit(): void {
    this.slug = this._planService.getTheSlug(this._route.snapshot.params.slug);

    this.getLogoFile();

    this.language = this.translate.currentLang;

    this.forgotPswdForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]      
    });
  }
 
  getLogoFile(): void {
    this.isImageLoading = true;
    this._planService.getCurentLogo().subscribe({
      next: data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      error: error => {
        this.isImageLoading = false;
        this.isImageValid = false;
      }
    });
  }
  
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.logoToDisplay = reader.result;
      this.isImageValid = true;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  forgotPassword() {
    this.isRequesting = true;
    this.errors = '';
    this.success = '';

    if (this.forgotPswdForm.invalid) {
      this.isRequesting = false;
      return;
    }
    if (this.forgotPswdForm.valid) {      
    this.submitted = true;
      const forgotPasswordModel: IForgotPassword = Object.assign({}, this.forgotPswdForm.value);
      this._auth.forgotPassword(forgotPasswordModel)
        .subscribe(() => {
          this.forgotPwdSucceeded = true;
        }).add(() => this.submitted = false);
    }
}

  close() {
    this._router.navigate([EcklerRouteConfig.loginRoute(this.slug)]);
  }
}
