<h2 class="dialogTitle">{{'Dialog.ChooseAccount' | translate}}</h2>
<mat-dialog-content>
    <mat-radio-group class="radio-group">

        <mat-radio-button class="mt-1" (change)="selectPlan($event)" *ngFor="let account of accounts" [value]="account.id">
        {{account.name}}
        </mat-radio-button>

        </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button type="button" mat-flat-button (click)="cancel()"  class="cancel-button">
        {{'Actions.Cancel' | translate}}
    </button>
    <button type="button" mat-flat-button color="accent" (click)="switchAccount()">
        {{'Actions.Switch' | translate}}
    </button>

</mat-dialog-actions>