import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageEnum } from 'src/app/shared/enum/language';
import { IAdministrator } from 'src/app/shared/interfaces/administrator';
import { AdministratorService } from 'src/app/shared/service/adminsitrator.service';
import { FormDirtyService } from 'src/app/shared/service/form-dirty.service';

@Component({
  selector: 'app-administrator-creation',
  templateUrl: './administrator-creation.component.html'
})
export class AdministratorCreationComponent implements OnInit {

  administratorCreationForm: UntypedFormGroup;
  languageList= LanguageEnum;
  langKeys: number [];
  administrator: IAdministrator;
  planId: number;

  constructor(public _dialogRef: MatDialogRef<AdministratorCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { planId: number },
    private _formBuilder: UntypedFormBuilder,
    private _dirty:FormDirtyService,
    private _administratorService: AdministratorService) { }

  ngOnInit(): void {
    const langArray = Object.keys(this.languageList).map(x => +x);
    langArray.splice((langArray.length/2), (langArray.length/2));
    this.langKeys = langArray;

    this.planId = this.data.planId;

    this.initialForm();

    this.administratorCreationForm.valueChanges.subscribe(x => this._dirty.formIsDirty()
    );
  }
  initialForm() {
    this.administratorCreationForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      language: ['']
    });
  }

  save() {
    const administrator: IAdministrator = Object.assign({}, this.administratorCreationForm.value);
    administrator.planId = this.planId;
    this._administratorService.createAdministrator(administrator).subscribe(result => {
      this._dirty.formIsClean();
      this._dialogRef.close(result);
    });
  }

  cancel(){
    this._dirty.formIsClean();
    this._dialogRef.close(null);
  }
}
