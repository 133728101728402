import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BatchInviteComponent } from 'src/app/dialog/batch-invite/batch-invite.component';
import { DeleteItemComponent } from 'src/app/dialog/delete-item/delete-item.component';
import { ImportMembersComponent } from 'src/app/dialog/import-members/import-members.component';
import { MemberCreationComponent } from 'src/app/dialog/member-creation/member-creation.component';
import { MemberModificationComponent } from 'src/app/dialog/member-modification-component/member-modification-component.component';
import { EcklerRouteConfig } from '../../eckler-route-config';
import { DeletionTypeEnum } from '../../enum/deletion-type';
import { UserTypeEnum } from '../../enum/user-type-enum';
import { IPlanMember } from '../../interfaces/planmember';
import { PagingRequest } from '../../interfaces/system/paging-request';
import { AuthService } from '../../service/auth.service';
import { FormDirtyService } from '../../service/form-dirty.service';
import { NotificationService } from '../../service/notification.service';
import { PagingHelperService } from '../../service/paging-helper.service';
import { PlanMemberService } from '../../service/planmember.service';
import { TitleService } from '../../service/title.service';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls:['./member-list.component.scss'],
  providers: [{ provide: PagingHelperService }]

})
export class MemberListComponent implements OnInit, OnDestroy {
  @Input() planId?: number = 0;
  selectedMember: IPlanMember;
  listOfMembers: IPlanMember[] = [];
  pagingHelpSubs: Subscription;
  active = 'createdOn';
  direction = 'desc';
  loading = false;
  criterias = '';
  currentUserType: UserTypeEnum;
  dirtySubs:Subscription;
  formDirty = false;  

  displayedColumns: string[] = ['fullName', 'status', 'optin', 'invitationSent', 'language', 'email', 'phone', 'sendInvite', 'delete'];
  dataSource = new MatTableDataSource(this.listOfMembers);

  constructor(
    private _router: Router,
    private _dirty:FormDirtyService,
    private _planmember: PlanMemberService,
    public dialog: MatDialog,
    private _auth: AuthService,
    public _pagingHelper: PagingHelperService,
    private _notification: NotificationService,
    private _translate: TranslateService,
    private _title:TitleService,
    private _activatedRoute: ActivatedRoute) { }
  @ViewChild(MatSort) sort: MatSort;
  authSub: Subscription;
  openUpload = false;

  ngOnInit(): void {
      this.authSub = this._auth.currentUser.subscribe(user => {
        if (user) {
          this.currentUserType = user.userType
          if(user.userType != UserTypeEnum.SuperUser) {
          this.planId = user.planId;
          } else {
            // Using snapshot
            this.planId = Number(this._activatedRoute.snapshot.params.id);

            // Using observable
            this._activatedRoute.params.subscribe(params => {
              this.planId = Number(params.id);
            });
          }
        }
      });

    this.pagingHelpSubs = this._pagingHelper.loadMore.subscribe(load => {
      if (load === true) {
        this.getData(this.criterias, this.active, this.direction);
      }
    });

    this.dirtySubs = this._dirty.formDirty$.subscribe(x => this.formDirty = x);

    this._title.updateTitle(this._translate.instant('Title.MemberList'));
    this.getData(this.criterias, this.active, this.direction);
  }

  getData(criteria: string, active?: string, direction?: string) {
    this.loading = true;
    const query: PagingRequest = {
      criterias: criteria,
      paging: this._pagingHelper.buildPaging(),
      sortBy: active,
      sortDirection: direction,
      planId: this.planId
    };
    this._planmember.getPlanMembers(query)
      .subscribe({
        next: res => {
          this.listOfMembers = [...this.listOfMembers, ...res.payload];
          this.dataSource.data.push(...res.payload);
          this._pagingHelper.updatePaging(res.metadata.total, this.listOfMembers.length);
          this.dataSource.sort = this.sort;
          this.loading = false;
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
  }

  sortData(e: Sort) {
    this.active = e.active;
    this.direction = e.direction;
    this.dataSource.data = [];
    this._pagingHelper.resetPaging();
    this.getData(this.criterias, e.active, e.direction);
  }

  getMemberDetails(row: IPlanMember) {
    if (this.currentUserType === UserTypeEnum.SuperUser) {
      const dialogRef = this.dialog.open(MemberModificationComponent, {
        width: '550px',
        data: {
          member: row
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this._notification.showSuccessMessage(this._translate.instant('Message.UpdatedSuccess'));
          let data = this.dataSource.data;
          data = [];
          this.listOfMembers = [];
          this.dataSource.data = data;
          this._pagingHelper.resetPaging();
          this.getData(this.criterias, this.active, this.direction);
        }
      });
    } else {
      this._router.navigateByUrl(EcklerRouteConfig.planPlanMembersDetailsRoute(row.id));
    }
  }

  createDialog() {
    const dialogRef = this.dialog.open(MemberCreationComponent, {
      width: '550px',
      data: { planId: this.planId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = this.dataSource.data;
        data.unshift(result);
        this.dataSource.data = data;
        this._notification.showSuccessMessage(this._translate.instant('Message.CreationSuccess'));
      }
    });
  }
  inviteDialog(){
    const dialogRef = this.dialog.open(BatchInviteComponent, {
      width: '550px',
      data: { planId: this.planId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._notification.showSuccessMessage(this._translate.instant('Message.InvitationSent'));
      }
    });
  }
  sendInvite(id: number) {
    this._planmember.inviteMember(id).subscribe(result => {
      this._notification.showSuccessMessage(this._translate.instant('Message.InvitationSent'));
    });
  }

  deletePlanMember(id: number) {
    const dialogRef = this.dialog.open(DeleteItemComponent, {
      width: '350px',
      data: { deletionType: DeletionTypeEnum.memberPlan }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._planmember.deletePlanMember(id).subscribe(() => {
          this._notification.showSuccessMessage(this._translate.instant('Message.DeleteSuccess'));
          let data = this.dataSource.data;
          data = [];
          this.listOfMembers = [];
          this.dataSource.data = data;
          this._pagingHelper.resetPaging();
          this.getData(this.criterias, this.active, this.direction);
        }
        );
      }
    });
  }

  openSection() {
    const dialogRef = this.dialog.open(ImportMembersComponent, {
      width: '600px',
      data: this.planId
    });
    dialogRef.afterClosed().subscribe(result => {

      let data = this.dataSource.data;
      data = [];
      this.listOfMembers = [];
      this.dataSource.data = data;
      this._pagingHelper.resetPaging();
      this.getData(this.criterias, this.active, this.direction);

    });
  }

  export() {
    const query: PagingRequest = {
      criterias: this.criterias,
      paging: this._pagingHelper.buildPaging(),
      sortBy: this.active,
      sortDirection: this.direction
    };
    this._planmember.exportToExcel(query, this.planId)
      .subscribe({
        next: x => {
          const newBlob = new Blob([x], { type: 'application/vnd.ms-excel' });
          const url = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'Members.xlsx';
          link.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          }));

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
            link.remove();
          }, 100);
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.pagingHelpSubs.unsubscribe();
    this.authSub.unsubscribe();
    this.dirtySubs.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if(this.formDirty === true){
      $event.returnValue = true;
    }
  }
}
