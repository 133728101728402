import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAdministrator } from '../../interfaces/administrator';
import { AdministratorService } from '../../service/adminsitrator.service';
import { NotificationService } from '../../service/notification.service';
import { PlanService } from '../../service/plan.service';

@Component({
  selector: 'app-contact-slug',
  templateUrl: './contactSlug.component.html',
  styleUrls:['./contactSlug.component.scss']
})
export class ContactSlugComponent implements OnInit {

  dataSource = new MatTableDataSource<IAdministrator>();
  loading = true;
  planId: number;
  displayedColumns: string[] = ['fullName', 'email', 'phone', 'language'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { slug: string },

    private _route: ActivatedRoute, private _plan: PlanService, private _administrator: AdministratorService, private _notification: NotificationService, private _translate: TranslateService) { }

  ngOnInit(): void {

    this.getMyAdmin();

  }

  getMyAdmin(){
    this._administrator.getViaSlugAdministrators(this.data.toString()).subscribe({
      next: res => {
        this.dataSource.data = res.filter(a => a.enabled);
        this.loading = false;
      },
      error:error => {
        this._notification.showErrorMessage(this._translate.instant('Error.ErrorGettingAdmin'));
        this.loading = false;
      }
    });
  }
}
