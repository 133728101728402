<div class="container py-5 mb-2 mt-5">
    <div class="row text-center">
        <div class="col d-flex justify-content-center">
            <img src="/assets/images/eckler-logo.svg" *ngIf="!isImageLoading && !isImageValid" alt="logo" class="p-0 m-0">
            <img [src]="logoToDisplay" *ngIf="!isImageLoading && isImageValid" alt="logo" class="logo p-0 m-0" /> 
        </div>
    </div>
    <div class="row justify-content-md-center mt-3">
        <div class="col-12 col-sm-6">
            <ng-container>
                <mat-card class="p-5" *ngIf="!forgotPwdSucceeded">
                    <div *ngIf="submitted">
                        <mat-spinner diameter="25" strokeWidth="1" class="float-left mr-2"></mat-spinner>
                        {{ 'Message.PleaseWait' | translate }}
                    </div>
                    <br />
                    <div class="mat-dialog-title">{{ 'Title.ForgotPassword' | translate }}</div>
                    <form [formGroup]="forgotPswdForm" (ngSubmit)="forgotPassword()">
                        <mat-form-field class="w-100">
                            <input type="text" matInput placeholder="{{'Common.Email' | translate }}" formControlName="email"
                                required>
                            <mat-error *ngIf="forgotPswdForm.controls.email.hasError('required')">{{'Error.Required' |
                                translate}}
                            </mat-error>
                            <mat-error *ngIf="forgotPswdForm.controls.email.hasError('email')">{{'Error.EmailInvalid' |
                                translate}}
                            </mat-error>
                        </mat-form-field>
                        <button mat-flat-button color="accent">{{ 'Actions.Submit' | translate }}</button>
                        <button mat-flat-button color="accent" style="float: right;" (click)="close()">{{ 'Actions.Cancel' | translate }}</button>
                    </form>
                </mat-card>
                <mat-card *ngIf="forgotPwdSucceeded">
                    <div *ngIf="language !== 'fr_CA'"><h4>{{ 'Common.ForgotPwdSucceeded' | translate }}</h4></div>
                    <p>{{ 'Message.ForgotPwdSucceeded' | translate }}</p>
                    <a type="button" mat-flat-button color="accent" (click)="close()">{{ 'Actions.Cancel' | translate }}</a>
                </mat-card>
            </ng-container>
        </div>
    </div>
</div>