import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldTypeEnum } from 'src/app/shared/enum/field-type';
import { IVerificationModify } from 'src/app/shared/interfaces/iverification-modify';
import { IVerificationField } from 'src/app/shared/interfaces/verification-field';
import { FormDirtyService } from 'src/app/shared/service/form-dirty.service';
import { PlanVerificationieldService } from 'src/app/shared/service/plan-verificationield.service';
import { VerificationMethodCreationComponent } from '../verification-method-creation/verification-method-creation.component';

@Component({
  selector: 'app-verification-method-modification',
  templateUrl: './verification-method-modification.component.html',
  styleUrls: ['./verification-method-modification.component.scss']
})
export class VerificationMethodModificationComponent implements OnInit {

  verificationModificationForm: UntypedFormGroup;
  verifictionfield:IVerificationField;
  verificationType= FieldTypeEnum;
  verKeys: number [];
  hasMember: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { verificationModify: IVerificationModify },
    private _fb:UntypedFormBuilder,
    private _dirty:FormDirtyService,
    private _service:PlanVerificationieldService,
    public dialogRef: MatDialogRef<VerificationMethodCreationComponent>) {
  }

  ngOnInit(): void {
    this.verifictionfield = this.data.verificationModify.verificationfield;
    this.hasMember = this.data.verificationModify.hasMember;

    const verArray = Object.keys(this.verificationType).map(x => +x);
    verArray.splice((verArray.length/2), (verArray.length/2));
    this.verKeys = verArray;

    this.initialForm();

    this.verificationModificationForm.valueChanges.subscribe(x => this._dirty.formIsDirty()
    );

  }

  initialForm(){
    this.verificationModificationForm = this._fb.group({
      verificationFieldLabel: [this.verifictionfield.verificationFieldLabel, Validators.required],
      fieldType: [this.verifictionfield.fieldType, Validators.required]
    });

    if(this.hasMember === true){
      this.verificationModificationForm.disable();
      this._dirty.formIsClean();

    }
  }

  modifyPlanVerification(){
    if (this.verificationModificationForm.dirty && this.verificationModificationForm.valid) {
      const fieldTosave: IVerificationField = Object.assign({}, this.verifictionfield, this.verificationModificationForm.value);

      this._service.updateVerificationField(this.verifictionfield.id, fieldTosave).subscribe({
        next: result => {
          this._dirty.formIsClean();
          this.dialogRef.close(true);
        }
      });
    }
  }

  cancel(){
    this._dirty.formIsClean();
    this.dialogRef.close(null);
  }

}
