import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { IPlan } from 'src/app/shared/interfaces/plan';
import { AuthService } from 'src/app/shared/service/auth.service';
import { FormDirtyService } from 'src/app/shared/service/form-dirty.service';

@Component({
  selector: 'app-switch-account',
  templateUrl: './switch-account.component.html',
  styleUrls: ['./switch-account.component.scss']
})
export class SwitchAccountComponent implements OnInit {
  accounts:IPlan[];
  loading = true;
  selectedPlan: number;

  constructor(private _auth:AuthService,
    public _dialogRef: MatDialogRef<SwitchAccountComponent>,
    private _dirty:FormDirtyService) { }

  ngOnInit(): void {
    this._auth.getPlans().subscribe({
      next: res => {
        this.accounts = res;
        this.loading = false;
      },
      error: error => {
        this.loading = false;
      }
    });

  }

  selectPlan(e: MatRadioChange){
    this.selectedPlan= e.value;

  }

  switchAccount(){
    this._auth.switchAccount(this.selectedPlan).subscribe(u => this._auth.updateCurrentUser(u));
    this._dialogRef.close(true);
  }

  cancel(){
    this._dirty.formIsClean();
    this._dialogRef.close(null);
  }

}
