<div class="container py-5 mb-2 mt-5">
    <div class="row text-center">
        <div class="col">
            <img src="/assets/images/eckler-logo.svg" *ngIf="!isLoading && !isImageValid" alt="logo" class="p-0 m-0">
            <img [src]="logoToDisplay" alt="logo" *ngIf="!isLoading && isImageValid" class="p-0 m-0" />

        </div>
    </div>
    <div class="row justify-content-md-center mt-3">
        <div class="col-12 col-sm-6">
            <mat-card class="p-5" *ngIf="!resetSucceeded">
                <div *ngIf="submitted || isLoading">
                    <mat-spinner strokeWidth="1" class="float-left mr-2"></mat-spinner>
                    {{ 'Message.PleaseWait' | translate }}
                </div>
                <br />
                <h4>{{ 'Common.SetPassword' | translate }}</h4>
                <form *ngIf="resetFormGroup" [formGroup]="resetFormGroup" (ngSubmit)="reset()">

                    <mat-form-field class="w-100 mb-3">
                        <input matInput placeholder="{{'Common.Email' | translate }}" formControlName="username"
                            required>
                        <mat-hint>{{'Login.EmailHint'|translate}}</mat-hint>
                        <mat-error *ngIf="resetFormGroup.controls.username.hasError('required')">
                            {{'Error.Required' | translate}}</mat-error>
                        <mat-error *ngIf="resetFormGroup.controls.username.hasError('email')">
                            {{'Error.EmailInvalid' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mb-5">
                        <input matInput minlength="8" #pass placeholder="{{'Common.Password' | translate }}"
                            type="password" formControlName="password" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&_-])[A-Za-z\d!@#$%^&_-]{8,}$">
                        <mat-hint>{{'Login.PasswordRequirement' | translate}}</mat-hint>
                        <mat-hint align="end">{{pass.value?.length || 0}}/8</mat-hint>
                        <mat-error *ngIf="resetFormGroup.controls.password.hasError('required')">
                            {{'Error.Required' | translate}}</mat-error>
                        <mat-error *ngIf="resetFormGroup.controls.password.hasError('minlength')">
                            {{'Error.PassMinlegth' | translate}}</mat-error>
                        <mat-error *ngIf="resetFormGroup.controls.password.hasError('pattern')">
                            {{'Error.PassPattern' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mb-4">
                        <input matInput minlength="8" #confirm placeholder="{{'Common.ReEnterPassword' | translate }}"
                            type="password" formControlName="passwordConfirm" required (input)="checkPasswords()"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&_-])[A-Za-z\d!@#$%^&_-]{8,}$">
                        <mat-hint align="end">{{confirm.value?.length || 0}}/8</mat-hint>
                        <mat-error *ngIf="resetFormGroup.controls.passwordConfirm.hasError('required')">
                            {{'Error.Required' | translate}}</mat-error>
                        <mat-error *ngIf="resetFormGroup.controls.passwordConfirm.hasError('notSame')">
                            {{'Error.PasswordNotMatch' | translate}}</mat-error>
                        <mat-error *ngIf="resetFormGroup.controls.passwordConfirm.hasError('minlength')">
                            {{'Error.PassMinlegth' | translate}}</mat-error>
                        <mat-error *ngIf="resetFormGroup.controls.passwordConfirm.hasError('pattern')">
                            {{'Error.PassPattern' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="resetFormGroup.value.isMfaActive" class="w-100">
                        <mat-select placeholder="{{ 'Common.MfaMethod' | translate }}" formControlName="mfaCommunicationMethod">
                            <mat-option [value]="1">
                                {{ 'Common.Email' | translate }}
                            </mat-option>
                            <mat-option [value]="2">
                                {{ 'Common.Mobile' | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="resetFormGroup.controls.mfaCommunicationMethod.hasError('required')">
                            {{'Error.Required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="resetFormGroup.value.isMfaActive && resetFormGroup.value.mfaCommunicationMethod === 2" class="w-100">
                        <input matInput mask="(000) 000-0000" placeholder="{{'Common.MobileNumber' | translate }}" formControlName = "mobile">
                    </mat-form-field>
                    <p>
                        <br />
                        <a class="force-link" [routerLink]="" (click)="openTermOfUse()">{{'Login.TermofUse'| translate}}</a><br /><br />
                        <mat-slide-toggle #termCondition>{{'Login.ReadAcceptedTC'| translate}}&nbsp;<a
                                (click)="openTermOfUse()">{{'Login.TermofUse' | translate}}</a></mat-slide-toggle>
                    </p>

                    <button mat-flat-button color="accent"
                        [disabled]="!termCondition.checked || !resetFormGroup.valid || submitted ">{{
                        'Actions.SetPassword' | translate }}</button>
                </form>
            </mat-card>
            <mat-card *ngIf="resetSucceeded">
                <h4>{{ 'Common.ChangeSucceeded' | translate }}</h4>
                <p>{{ 'Message.ResetSucceeded' | translate }}</p>
                <a type="button" mat-flat-button color="accent" (click)="goToLogin()">{{ 'Actions.Login' | translate
                    }}</a>
            </mat-card>
        </div>
    </div>
</div>