import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export function ApplicationInitializerFactory(translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    const currentLocalStorage = JSON.parse(localStorage.getItem('ecklerUser'));

    const localeToCheck = currentLocalStorage?.language === 2 ? 'fr_CA' : 'en_CA';
    const platformLocales = ['fr_CA', 'en_CA'];
    const defaultLang = platformLocales.find(x => x === localeToCheck) ?? 'en_CA';

    translate.addLangs(platformLocales);
    translate.setDefaultLang(defaultLang);
    translate.use(defaultLang);
  };
}
