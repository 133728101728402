import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PagingHelperService } from '../../service/paging-helper.service';

@Component({
  selector: 'app-paging-helper',
  templateUrl: './paging-helper.component.html'

})
export class PagingHelperComponent implements OnInit, OnDestroy {
  showmoreButtonSub: Subscription;

  showLoadMoreBtn = true;

  constructor(
    private service: PagingHelperService
  ) {}

  ngOnInit() {
    this.showmoreButtonSub = this.service.showLoadMoreButton.subscribe(value => this.showLoadMoreBtn = value);
  }

  loadMore() {

    this.service.loadMoreData();
  }

  ngOnDestroy(): void {
    this.showmoreButtonSub.unsubscribe();
  }

}
