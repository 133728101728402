<h2 class="dialogTitle">{{'Dialog.BatchInvite' | translate}}</h2>
<mat-dialog-content *ngIf="batchInviteForm">
<form [formGroup]="batchInviteForm">    
    <mat-form-field class="w-100">
        <mat-label>{{'Common.SendTo' | translate}}</mat-label>
          <mat-select formControlName="sendTo">
            <mat-option value="1">{{'Common.NotRegisteredYet' | translate}}</mat-option>
            <mat-option value="2">{{'Common.NotInvitedYet' | translate}}</mat-option>
            <mat-option value="3">{{'Common.Everyone' | translate}}</mat-option>
          </mat-select>          
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Common.SendMethod' | translate}}</mat-label>
          <mat-select formControlName="sendMethod">
            <mat-option value="1">{{'Common.Email' | translate}}</mat-option>
            <mat-option value="2">{{'Common.Mail' | translate}}</mat-option>
          </mat-select>          
    </mat-form-field>
   </form>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-flat-button (click)="cancel()" class="cancel-button">
        {{'Actions.Cancel' | translate}}
    </button>
    <button type="button" mat-flat-button (click)="sendInvitations()" color="accent" [disabled]="!batchInviteForm.valid">
        {{'Actions.Submit' | translate}}
    </button>

</mat-dialog-actions>
