import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SwitchAccountComponent } from 'src/app/dialog/switch-account/switch-account.component';
import { environment } from 'src/environments/environment';
import { EcklerRouteConfig } from '../eckler-route-config';
import { UserTypeEnum } from '../enum/user-type-enum';
import { AuthService } from '../service/auth.service';
import { FormDirtyService } from '../service/form-dirty.service';
import { NotificationService } from '../service/notification.service';

@Component({
  selector: 'app-menu-context',
  templateUrl: './menu-context.component.html',
  styleUrls: ['./menu-context.component.scss']
})
export class MenuContextComponent implements OnInit {
  currentAppVersion: string;
  numberofplan: number;
  authSub: Subscription;

  constructor(private _router:Router, private _auth:AuthService, public dialog:MatDialog, private _notification:NotificationService, private _translate: TranslateService, private _dirty:FormDirtyService) {}

  ngOnInit(): void {
    this.currentAppVersion = environment.appVersion;
    this._auth.getPlans().subscribe({
      next: res => {
        this.numberofplan = res.length;
      }

    });

  }

  changePassword(){
    this._router.navigateByUrl(EcklerRouteConfig.changePasswordRoute());

  }

  switchPlan(){
    const dialogRef = this.dialog.open(SwitchAccountComponent, {
      width: '550px'

    });
    dialogRef.afterClosed().subscribe(res => {
      if (res === true) {
        this._notification.showSuccessMessage(this._translate.instant('Message.AccountSwitchSuccess'));
        this.authSub = this._auth.currentUser.subscribe(u => {
          if (u.userType === UserTypeEnum.SuperUser){
            this._router.navigate([EcklerRouteConfig.ecklerPlanListRoute()]);
          } else if (u.userType === UserTypeEnum.Administrator){
            this._router.navigate([EcklerRouteConfig.planPlanMembersListRoute()]);
          } else if (u.userType === UserTypeEnum.Member){
            this._router.navigate([EcklerRouteConfig.planMemberPortalRoute()]);
          }
        });

      }
    }
    );
  }

  signOut(){
    this._dirty.formIsClean();
    this._auth.signOut();
  }

}
