<h2 class="dialogTitle">{{'Dialog.GeneralFileUpload' | translate}}</h2>
<mat-dialog-content>
    <div class="row row justify-content-end align-items-center mx-2 mt-3">
        <mat-spinner *ngIf="uploading"></mat-spinner>
        <ng-container *ngIf="!uploading && !uploaded">
            <mat-form-field>
                <mat-label>{{'Common.DocumentName'| translate }}</mat-label>
                <input required matInput [(ngModel)] = "documentName"/>
            </mat-form-field>
            <mat-label>{{'Member.UploadFile'|translate}}</mat-label>
            <div *ngIf="!uploaded" class="d-flex flex-row mt-1">
                <label>
                    <input type="file" id="file" class="d-none" #fileInput (change)="uploadGeneralFile()"
                        [accept]="'application/pdf'">
                    <a mat-flat-button for="uploadfile" [disabled]="!documentName" matTooltip="{{'Actions.UploadDocument'|translate}}" class="upload-icon" matTooltipClass ="tooltip">
                        <mat-icon>add_photo_alternate</mat-icon>
                    </a>
                </label>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-flat-button color="accent" [mat-dialog-close]="null">
        {{'Actions.Close' | translate}}
    </button>
</mat-dialog-actions>