<div class="row justify-content-between align-items-center mx-2 mt-3 mb-2">
  <div class="col justify-content-end d-flex">
    <button (click)="openSection()" mat-flat-button color="accent" class="me-1" matTooltip="{{'Tooltip.UploadMember' | translate}}" matTooltipClass ="tooltip">
      <mat-icon>upload_file</mat-icon>{{'Actions.UploadMember' | translate}}
    </button>
    <button mat-flat-button color="accent" class="me-1" matTooltip="{{'Tooltip.ExportWithFilter' | translate}}" (click)="export()"
      type="button" matTooltipClass ="tooltip">
      <mat-icon>cloud_download</mat-icon>{{'Actions.ExportwFilter' | translate}}
    </button>
    <button mat-flat-button color="accent" (click)="inviteDialog()" class="me-1" matTooltip="{{'Actions.SendInvite' | translate}}" matTooltipClass ="tooltip">
      <mat-icon>forward_to_inbox</mat-icon>{{'Actions.SendInvite' | translate}}
    </button>
    <button mat-flat-button color="accent" (click)="createDialog()" matTooltip="{{'Tooltip.NewMember' | translate}}" matTooltipClass ="tooltip">
      <mat-icon>person_add_alt</mat-icon>{{'Actions.NewMember' | translate}}
    </button>    
  </div>
</div>
<mat-spinner *ngIf="loading"></mat-spinner>
<mat-table *ngIf="dataSource && dataSource.data.length > 0" [dataSource]="dataSource" class="mat-elevation-z8 mx-2">
  <ng-container matColumnDef="fullName">
    <mat-header-cell *matHeaderCellDef class="col"> {{'Common.FullName' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col"> {{planmember.firstName}}&nbsp;{{planmember.lastName}}

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center"> {{'Common.Status' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-1 justify-content-center"> 
      <mat-icon *ngIf="planmember.registered && planmember.enabled" color="accent"
        matTooltip="{{'Member.Registred'|translate}}" matTooltipClass ="tooltip">verified</mat-icon>
      <mat-icon *ngIf="!planmember.enabled" class="lowopacity" matTooltip="{{'Member.Disabled'|translate}}" matTooltipClass ="tooltip">no_accounts
      </mat-icon></mat-cell>
  </ng-container>

  <ng-container matColumnDef="language">
    <mat-header-cell *matHeaderCellDef class="col-1"> {{'Common.Language' | translate}}
    </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-1"> {{'Enum.Language' | translate:{type:planmember.language} }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef class="col-2"> {{'Common.Email' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-2"> 
      {{planmember.emailUsedForAuthentication}}
      <ng-container *ngIf="planmember.email && planmember.email !== planmember.emailUsedForAuthentication">
        ({{planmember.email}}) 
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef class="col-2"> {{'Common.Mobile' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-2"> {{planmember.mobile | mask: '(000) 000-0000'}} </mat-cell>
  </ng-container>


  <ng-container matColumnDef="optin">
    <mat-header-cell *matHeaderCellDef class class="col-1 justify-content-center text-center">
      {{'Common.OptIn' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-1 justify-content-center">
      <mat-icon color="accent" *ngIf="planmember.optIn">mark_chat_read</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="invitationSent">
    <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center text-center"> {{'Common.InvitationSent' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-1 justify-content-center"> <mat-icon *ngIf="planmember.invitationSentViaEmail === true" color = "primary">mark_email_read</mat-icon> </mat-cell>
  </ng-container>


  <ng-container matColumnDef="sendInvite">
    <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center"> {{'Actions.SendInvite' | translate}}
    </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-1 justify-content-center"><button mat-icon-button color="accent"
        (click)="sendInvite(planmember.id); $event.stopPropagation()">
        <mat-icon>forward_to_inbox</mat-icon>
      </button> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="delete">
    <mat-header-cell *matHeaderCellDef class="col-1 justify-content-center"> {{'Actions.Delete' | translate}}
    </mat-header-cell>
    <mat-cell *matCellDef="let planmember" class="col-1 justify-content-center"> <button mat-icon-button color="warn"
        (click)="deletePlanMember(planmember.id);  $event.stopPropagation()">
        <mat-icon>delete</mat-icon>
      </button></mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row (click)="getMemberDetails(row)" *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<app-paging-helper></app-paging-helper>
<app-no-data *ngIf="(!dataSource || dataSource.data.length <= 0) && !loading"></app-no-data>
