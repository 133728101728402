import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Paging } from '../interfaces/system/paging';

@Injectable({
  providedIn: 'root'
})
export class PagingHelperService {

  private showLoadMoreButton$: Subject<boolean> = new Subject<boolean>();
  private loadMore$: Subject<boolean> = new Subject<boolean>();
  loadMore = this.loadMore$.asObservable();
  showLoadMoreButton = this.showLoadMoreButton$.asObservable();

  private pageSize = 50;
  private payloadCurrent = 0;
  payloadTotal = 0;

  resetPaging() {
    this.payloadCurrent = 0;
    this.payloadTotal = 0;
    this.refreshUI();
  }

  updatePaging(totalLength: number, dataLength: number) {
    this.payloadTotal = totalLength;
    this.payloadCurrent = dataLength;
    this.refreshUI();
  }

  loadMoreData() {
    if (this.payloadCurrent < this.payloadTotal) {
      this.loadMore$.next(true);
    }
  }

  private refreshUI() {
    this.showLoadMoreButton$.next(this.payloadCurrent < this.payloadTotal);
  }

  buildPaging(): Paging {
    return {
      offset: this.payloadCurrent/this.pageSize,
      limit: this.pageSize
    };
  }

}
