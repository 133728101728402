
<mat-toolbar *ngIf="user === 'Member'"  class="breacrumbs">
  <a routerLink="/member">{{'Title.Home'|translate }}</a> >
  {{'SECUREMAIL.TITLE'|translate }}</mat-toolbar>
<div class="box-container">
  <h1 class="mx-2 mt-3">{{'SECUREMAIL.TITLE'|translate }}</h1>
  <div id="mailView" class="container-fluid">
    <div class="subheader">
      <span>Secure Messaging</span>
    </div>
    <br />
    <div *ngIf="this.id && this.id != '0'" id="inboxView">
      <mat-form-field class="mail-select" appearance="fill">
        <mat-select  [(ngModel)]="mailbox" (selectionChange)="mailboxChanged($event.value)">
          <mat-option *ngFor="let mail of mailboxes; let i = index" [value]="i">{{mail.person.name}}</mat-option>
        </mat-select>
      </mat-form-field>   
      <lib-message-inbox [auth_token]="auth_token" [sender]="sender" [to_list]="to_list"
        [category]="category" [expiry]="expiry" [hide_email]="hide_email" [language]="language"></lib-message-inbox>
    </div>
  </div>
</div>


  