<ng-container  *ngIf="form">
<div [formGroup]="form">
    <div [ngSwitch]="question.fieldType">
        <mat-form-field *ngSwitchCase="FieldTypeEnum.text" class="w-100">
            <input [formControlName]="question.id.toString()" matInput [placeholder]="question.verificationFieldLabel" required>   
            <mat-error *ngIf="!isValid">{{'Error.Required' | translate}}</mat-error>                     
        </mat-form-field>
        <mat-form-field *ngSwitchCase="FieldTypeEnum.number" class="w-100">
            <input type="number" [formControlName]="question.id" matInput
                [placeholder]="question.verificationFieldLabel" required>
                <mat-error *ngIf="!isValid">{{'Error.Required' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="FieldTypeEnum.date" class="w-100">
            <input matInput [formControlName]="question.id" [matDatepicker]="picker" [max]="maxDate | date:'yyyy/MM/dd'" 
                    [placeholder]="question.verificationFieldLabel" startView="multi-year" required>
            <mat-hint>YYYY/MM/DD</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="!isValid">{{'Error.Required' | translate}}</mat-error>
        </mat-form-field>
    </div>    
</div>
</ng-container>

<app-no-data *ngIf="!form"></app-no-data>