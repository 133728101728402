import { Component, Input, ViewChild, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AdministratorCreationComponent } from 'src/app/dialog/administrator-creation/administrator-creation.component';
import { AdministratorModificationComponent } from 'src/app/dialog/administrator-modification/administrator-modification.component';
import { DeleteItemComponent } from 'src/app/dialog/delete-item/delete-item.component';
import { DeletionTypeEnum } from '../../enum/deletion-type';
import { IAdministrator } from '../../interfaces/administrator';
import { PagingRequest } from '../../interfaces/system/paging-request';
import { AdministratorService } from '../../service/adminsitrator.service';
import { FormDirtyService } from '../../service/form-dirty.service';
import { NotificationService } from '../../service/notification.service';
import { PagingHelperService } from '../../service/paging-helper.service';
import { TitleService } from '../../service/title.service';

@Component({
  selector: 'app-administrator-list',
  templateUrl: './administrator-list.component.html',
  providers:[{provide: PagingHelperService}]

})
export class AdministratorListComponent implements OnInit, OnDestroy {
  @Input() planId:number;
  selectedAdministrator: IAdministrator;
  listOfAdministrators: IAdministrator[] = [];
  pagingHelpSubs: Subscription;
  criterias = '';
  active:string;
  direction: string;
  loading = false;
  dirtySubs: Subscription;
  formDirty = false;

  displayedColumns: string[] = ['fullName', 'invitationSent', 'email', 'phone', 'language', 'mainContact', 'sendInvite', 'delete'];
  dataSource = new MatTableDataSource(this.listOfAdministrators);

  constructor(
    private _administrator:AdministratorService,
    public dialog: MatDialog,
    public _pagingHelper:PagingHelperService,
    private _notification:NotificationService,
    private _translate:TranslateService,
    private _title:TitleService,
    private _dirty:FormDirtyService) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.pagingHelpSubs = this._pagingHelper.loadMore.subscribe(load => {
      if (load === true) {
        this.getData(this.criterias, this.active, this.direction);

      }
    });

    this.dirtySubs = this._dirty.formDirty$.subscribe(
      x => this.formDirty = x
    );

    this._title.updateTitle(this._translate.instant('Title.AdministratorList'));
    this.getData(this.criterias);

  }

  getData(criteria: string, active?: string, direction?: string){
    this.loading = true;
    const query: PagingRequest = {
      criterias: criteria,
      paging: this._pagingHelper.buildPaging(),
      sortBy: active,
      sortDirection: direction
    };
    this._administrator.getAdministrators(query, this.planId)
      .subscribe({
        next: res => {
          this.listOfAdministrators = [...this.listOfAdministrators, ...res.payload];
          this.dataSource.data.push(...res.payload);
          this._pagingHelper.updatePaging(res.metadata.total, this.listOfAdministrators.length);
          this.dataSource.sort = this.sort;
          this.loading = false;
        },
        error: error => {
          this._notification.showErrorMessage(error);
          this.loading= false;
        }
      });
  }

  sortData(e: Sort){
    this.active= e.active;
    this.direction = e.direction;
    this.dataSource.data = [];
    this._pagingHelper.resetPaging();
    this.getData(this.criterias, e.active, e.direction);
  }

  getAdministratorDetails(row:IAdministrator){
    const dialogRef = this.dialog.open(AdministratorModificationComponent, {
      width: '550px',
      data: {
        administrator: row
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._notification.showSuccessMessage(this._translate.instant('Message.UpdatedSuccess'));
        let data = this.dataSource.data;
        data = [];
        this.listOfAdministrators = [];
        this.dataSource.data = data;
        this._pagingHelper.resetPaging();

        this.getData(this.criterias, this.active, this.direction);
      }
    }
    );
  }

  createDialog(){
    const dialogRef = this.dialog.open(AdministratorCreationComponent, {
      width: '550px',
      data: {planId: this.planId}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = this.dataSource.data;
        data.push(result);
        this.dataSource.data = data;
        this._notification.showSuccessMessage(this._translate.instant('Message.CreationSuccess'));
      }
    });
  }

  sendInvite(id: number){
    this._administrator.inviteAdministrator(id).subscribe(result => {
      this._notification.showSuccessMessage(this._translate.instant('Message.InvitationSent'));
    });
  }

  deleteAdmin(id:number){
    const dialogRef = this.dialog.open(DeleteItemComponent, {
      width: '350px',
      data: {deletionType: DeletionTypeEnum.administrator}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._administrator.deleteAdministrator(id).subscribe(() => {
          this._notification.showSuccessMessage(this._translate.instant('Message.DeleteSuccess'));
          let data = this.dataSource.data;
          data = [];
          this.listOfAdministrators = [];
          this.dataSource.data = data;
          this._pagingHelper.resetPaging();
          this.getData(this.criterias, this.active, this.direction);
        }
        );
      }
    });
  }

  makeMainContact(administratorId: number){
    this._administrator.makeAdministratorMainContact(administratorId, this.planId).subscribe(() => {
      this._notification.showSuccessMessage(this._translate.instant('Message.UpdatedSuccess'));
      let data = this.dataSource.data;
      data = [];
      this.listOfAdministrators = [];
      this.dataSource.data = data;
      this._pagingHelper.resetPaging();
      this.getData(this.criterias, this.active, this.direction);
    });
  }
  ngOnDestroy(): void {
    this.pagingHelpSubs.unsubscribe();
    this.dirtySubs.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if(this.formDirty === true){
      $event.returnValue = true;
    }
  }
}
