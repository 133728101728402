<div *ngIf="parentForm">
<form class="mt-3 mx-2" [formGroup]="parentForm">
    <mat-form-field class="w-100">
        <mat-label>{{'Address.AdressLine1' | translate}}</mat-label>
        <input required matInput placeholder="{{'Address.AdressLine1' | translate}}" formControlName="addressLineOne">
        <mat-error *ngIf="parentForm.controls.addressLineOne.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Address.AdressLine2' | translate}}</mat-label>
        <input matInput placeholder="{{'Address.AdressLine2' | translate}}" formControlName="addressLineTwo">
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Address.City' | translate}}</mat-label>
        <input required matInput placeholder="{{'Address.City' | translate}}" formControlName="city">
        <mat-error *ngIf="parentForm.controls.city.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Address.PostalBox' | translate}}</mat-label>
        <input matInput placeholder="{{'Address.PostalBox' | translate}}" formControlName="postalBox">
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Common.Province' | translate}}</mat-label>
          <mat-select required formControlName="province">
           <mat-option *ngFor="let key of provKeys" [value]="key">{{'Enum.Province'| translate:{type:key} }}</mat-option>
          </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>{{'Address.PostalCode' | translate}}</mat-label>
        <input required #postalCode matInput mask="A0A 0A0" (input)="postalCode.value = postalCode.value.toUpperCase()" placeholder="{{'Address.PostalCode' | translate}}" formControlName="postalCode">
        <mat-error *ngIf="parentForm.controls.postalCode.hasError('required')">
            {{'Error.Required' | translate}}
        </mat-error>
    </mat-form-field>
</form>
</div>

<app-no-data *ngIf="!parentForm"></app-no-data>
