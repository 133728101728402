import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ProvinceEnum } from '../../enum/province';
import { IAddress } from '../../interfaces/address';
import { IPlan } from '../../interfaces/plan';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit{
  @Input() parentForm: UntypedFormGroup;
  provinceList = ProvinceEnum;
  provKeys:number[];

  constructor() { }

  ngOnInit(): void {
    const provincearray = Object.keys(this.provinceList).map(x => +x);
    provincearray.splice((provincearray.length/2), (provincearray.length/2));
    this.provKeys = provincearray;

  }

}
